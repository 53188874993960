import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/store-config';
import { Provider } from 'react-redux';
import WalletProvider from './wallet-module/provider/WalletProvider';
import ProviderMessagesHandler from './providers/messagehander/ProviderMessagesHandler';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';

import './style/main/css/bootstrap.min.css';
import './style/main/css/style.css';
import './style/main/css/colors/scheme-01.css';
// import './style/main/css/footer.css'
import './style/main/css/coloring.css';
import { ListViewProvider } from './wallet-module/provider/ViewProvider';

/*import TagManager from 'react-gtm-module';
TagManager.initialize({
  gtmId: process.env.REACT_APP_GTAG_ID,
  dataLayer: {
    js: new Date(),
    config: process.env.REACT_APP_GTAG_ID,
  },
});*/

const walletProviderPros = {
  supportedChainID: parseInt(process.env.REACT_APP_SUPPORTED_CHAIN ?? '0'),
  walletConnectProjectID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '',
};
window.Buffer = window.Buffer || require('buffer').Buffer;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WalletProvider credentials={walletProviderPros}>
    <ProviderMessagesHandler>
      <ListViewProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer className="custom_toastify_1" toastClassName="custom_toastify" />
        </BrowserRouter>
      </Provider>
      </ListViewProvider>
    </ProviderMessagesHandler>
  </WalletProvider>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
