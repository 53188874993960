let initialState = {
  query: '',
  filters: {
    all: [
      {
        is_active: 'true',
      },
    ],
  },
  page: {
    size: 24,
    current: 1,
  },
  sort: {
    placement: 'asc',
  },
};

const marketplaceFilterReducer = (state = { page: 1 }, action) => {
  switch (action.type) {
    case 'SET_MARKETPLACE_FILTERS':
      return action.filters;
    default:
      return state;
  }
};

export default marketplaceFilterReducer;
