let initialState = {
  collections: [],
  is_loading: false,
  total: 0,
  load_more: false,
};

const marketplaceCollectionListingReducer = (state = initialState, action) => {
  const data = action.payload;
  // console.log(data);
  switch (action.type) {
    case 'RESET_COLLECTION':
      return {
        ...state,
        collections: [],
        total: 0,
      };
    case 'SET_COLLECTION_LISTING':
      return {
        ...state,
        collections: [...state.collections, ...data.collections],
        total: data.total,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        is_loading: action.is_loading,
      };
    case 'TOGGLE_LOAD_MORE_LOADING':
      return {
        ...state,
        load_more: action.load_more,
      };
    default:
      return state;
  }
};

export default marketplaceCollectionListingReducer;
