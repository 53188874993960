import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
import LoaderComponent from './customs/loader/LoaderComponent';

const Author = (props) => {
  const { store } = useContext(StoreContext);
  const AuthorComponent = useDynamicComponent('Author', store);

  if (!AuthorComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <AuthorComponent />
    </>
  );
};

export default Author;
