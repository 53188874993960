export const APP_ENV = process.env.REACT_APP_ENV;
export const STORE_ID = process.env.REACT_APP_STORE_ID;

export const GCP_API_URL = process.env.REACT_APP_GCP_API_URL;
// export const GCP_API_URL_PAGINATION = process.env.REACT_APP_GCP_API_URL2;
export const LEDGER_API_URL = process.env.REACT_APP_LEDGER_API_URL;
export const TOP_COLLECTION_API_URL = process.env.REACT_APP_TOP_COLLECTION_API_URL;

export const ELASTIC_SEARCH_API_URL = process.env.REACT_APP_ELASTIC_SEARCH_API_URL;
export const ELASTIC_SEARCH_NFT_URI = process.env.REACT_APP_ELASTIC_SEARCH_NFT_URI;
export const BAZAA_URL=process.env.REACT_APP_GCP_API_URL;
export const ELASTIC_SEARCH_COLLECTION_URI = process.env.REACT_APP_ELASTIC_SEARCH_COLLECTION_URI;
// export const ELASTIC_SEARCH_MARKETPLACE_ASSETS_ENGINE_NAME = process.env.REACT_APP_ELASTIC_SEARCH_MARKETPLACE_ASSETS_ENGINE_NAME
// export const ELASTIC_SEARCH_MARKETPLACE_NFTS_ENGINE_NAME = process.env.REACT_APP_ELASTIC_SEARCH_MARKETPLACE_NFTS_ENGINE_NAME
export const ELASTIC_SEARCH_SEARCH_KEY = process.env.REACT_APP_ELASTIC_SEARCH_SEARCH_KEY;

export const CRYPTO_COMPARE_API = process.env.REACT_APP_CRYPTO_COMPARE_API;

export const PROFILE_WEB_URL = process.env.REACT_APP_PROFILE_WEB_URL;

//export const MINTER_WEB_URL = 'https://mint.bimtvi.com';
export const MINTER_WEB_URL = process.env.MINTER_WEB_URL

export const REACT_APP_CONTRACT_METADATA_BASE = process.env.REACT_APP_CONTRACT_METADATA_BASE;
export const REACT_APP_ASSET_METADATA_BUCKET = process.env.REACT_APP_ASSET_METADATA_BUCKET;
export const REACT_APP_CDN_BUCKET = process.env.REACT_APP_CDN_BUCKET;
export const REACT_APP_VANAR_EXPLORE_BASE = process.env.REACT_APP_VANAR_EXPLORE_BASE;
export const REACT_APP_EXCHANGE_CONTRACT = process.env.REACT_APP_EXCHANGE_CONTRACT;
export const REACT_APP_EXCHANGE_CONTRACT_V2 = process.env.REACT_APP_EXCHANGE_CONTRACT_V2;
export const REACT_APP_EXCHANGE_CONTRACT_1155 = process.env.REACT_APP_EXCHANGE_CONTRACT_1155;
export const REACT_APP_EXCHANGE_PROXY_CONTRACT = process.env.REACT_APP_EXCHANGE_PROXY_CONTRACT;
export const REACT_APP_PLATFORM_FEE_ADDRESS = process.env.REACT_APP_PLATFORM_FEE_ADDRESS;
export const RPC_VANAR = process.env.REACT_APP_RPC_VANAR;
export const ANALYTICS_API = process.env.REACT_APP_COLLECTION_ANALYTICS_URL;
export const DISCORD_CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
export const DISCORD_CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
export const DISCORD_REDIRECT_URI_PROFILE = process.env.REACT_APP_DISCORD_REDIRECT_URI_PROFILE;
export const DISCORD_REDIRECT_URI_COLLECTION =
  process.env.REACT_APP_DISCORD_REDIRECT_URI_COLLECTION;
export const DISCORD_TOKEN_VERIFY_URI = 'https://discord.com/api/oauth2/token';
export const DISCORD_GET_PROFILE_URL = 'https://discord.com/api/v10/users/@me';
export const ANALYTICS_BASE_URL = process.env.REACT_APP_ANALYTICS_BASE_URL;
export const COLLECTIONS_API=process.env.REACT_APP_CLOUD_COLLECTIONS;