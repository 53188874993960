import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';

import LoaderComponent from './customs/loader/LoaderComponent';
const NftDetail = (props) => {
  const { store } = useContext(StoreContext);
  const NftDetailComponent = useDynamicComponent('NftDetail', store);

  if (!NftDetailComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <NftDetailComponent />
    </>
  );
};

export default NftDetail;
