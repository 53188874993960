import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL } from '../utils/consts';

const appLogoSmall = `${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}logo.svg`;
const tickLogo = `${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}check_icon.svg`;
const defaultLogo = `${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}placeholder-img.svg`;
const defaultBrokenImage = `${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}placeholder.svg`;
const handleAvatarImageError = (event) => {
  event.target.src = defaultBrokenImage;
};
const handleBrokenImageError = (event) => {
  event.target.src = defaultBrokenImage;
};
export {
  appLogoSmall,
  tickLogo,
  defaultLogo,
  handleAvatarImageError,
  handleBrokenImageError,
  defaultBrokenImage,
};
