import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../loader/LoaderComponent';
import useWallets from '../../../wallet-module/hooks/useWallets';

const PrivateRoute = ({ component: Component }) => {
  const { vanarAddressLowerCase } = useWallets();
  const navigate = useNavigate();
  // console.log('loginUser::', vanarAddressLowerCase);
  if (vanarAddressLowerCase === undefined || vanarAddressLowerCase === null) {
    setTimeout(() => {
      navigate('/collections');
    }, 3000);

    return <LoaderComponent />;
  }
  return <Component />;
};

export default PrivateRoute;
