import { useMemo } from 'react';
import { useAccount, useDisconnect, useChainId, useSignMessage, useNetwork } from 'wagmi';
import { useWalletProviderContext } from '../../wallet-module/provider/WalletProvider';
const useWallets = () => {
  const { chain: currentProviderChain } = useNetwork();

  const { disconnectAsync, reset: resetETH } = useDisconnect();
  const { supportedChainID } = useWalletProviderContext();
  const {
    address: ethAddress,
    isConnected: isConnectedETH,
    isConnecting: isConnectingETH,
    connector,
  } = useAccount();
  const chainIdETH = useChainId();

  const { signMessageAsync } = useSignMessage();

  const resetWallets = () => {
    disconnectAsync();
  };

  const ethWalletInfo = useMemo(
    () => ({
      ethAddress,
      isConnectedETH,
      isConnectingETH,
      ethWalletName: connector?.name,
      blockchain: 'VANAR',
      chainId: chainIdETH,
    }),
    [ethAddress, isConnectedETH, isConnectingETH, connector?.name, chainIdETH],
  );
  /* Disconnection */
  const disconnectETHWallets = async () => {
    // TODO: LogOut user form firebase as well
    // TODO: Rest Redux Store (Cart assets)
    await disconnectAsync();
    setTimeout(() => {
      resetETH();
    }, 500);
  };
  /*  ----------- */
  return {
    vanarAddressLowerCase: ethAddress?.toLowerCase(),
    vanarAddress: ethAddress,
    ethAddress,
    ethWalletInfo,
    isSupportedChain: supportedChainID === currentProviderChain?.id,
    signMessageAsync,
    resetWallets,
    disconnectETHWallets,
  };
};

export default useWallets;
