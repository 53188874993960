let initialState = {
  data: {},
  is_login: false,
  is_profile_updated: false,
  isDarkMode: JSON.parse(localStorage.getItem('darkMode') || false),
};
const profile = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PROFILE':
      return {
        ...state, // Spread the existing state attributes
        is_login: true,
        data: {
          ...state.data,
          ...action.profile.data, // Update only the 'data' attribute
        },
      };
    case 'UPDATE_LOGIN':
      return {
        ...state,
        is_login: action.is_login,
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        is_profile_updated: action.is_profile_updated,
      };
    case 'DARK_MODE_UPDATE':
      return {
        ...state,
        isDarkMode: action.isDarkMode,
      };
    default:
      return state;
  }
};

export default profile;
