const initialState = {
  contractAddress: '',
  searchTerm: '',
  categories: [],
  sortAt: 'created_at',
  sortTerm: 'desc',
  from: 0,
  page:1,
  limit:24
};

const marketplaceCollectionListingQueryReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'SET_COLLECTION_LISTING_QUERY':
      return action.collection_query;
    case 'RESET_COLLECTION_LISTING_QUERY': {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default marketplaceCollectionListingQueryReducer;
