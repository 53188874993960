/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-empty-function */
import React, { useContext, useRef, useState } from 'react';
import ModalCommonMessage from './alerts/ModalCommonMessage';
import ModalErrorMsg from './alerts/ModalErrorMsg';


export const MessagesHandlerContext =
	React.createContext({});

const ProviderMessagesHandler = ({
	children,
}) => {
	const closeCallBackRef = useRef();
	const [state, setState] = useState({
		errorMessage: null,
		errorTitle: null,
		succcsesTitle: null,
		successMessage: null,
		errorImage: false,
	});

	const setErrorStateHandler = (
		message,
		title,
		ctaTitle,
		closeCallBack,
		errorImage
	) => {
		if (message !== state.errorMessage || title !== state.errorTitle) {
			setState((prvState) => ({
				...prvState,
				errorMessage: message,
				errorTitle: title,
				ctaTitle,
				errorImage,
			}));
		}
		closeCallBackRef.current = closeCallBack;
	};
	const setMessageStateHandler = (
		message,
		title,
		ctaTitle,
		closeCallBack
	) => {
		if (message !== state.successMessage || title !== state.succcsesTitle) {
			setState((prvState) => ({
				...prvState,
				successMessage: message,
				succcsesTitle: title,
				ctaTitle,
			}));
		}
		closeCallBackRef.current = closeCallBack;
	};
	const closeErrorModal = (isBtnCloseClicked) => {
		setState((prvState) => ({
			...prvState,
			errorMessage: null,
			errorTitle: null,
			errorImage: false,
			ctaTitle: null,
		}));
		if (isBtnCloseClicked) {
			closeCallBackRef.current?.();
		}
		closeCallBackRef.current = undefined;
	};
	const closeSuccessModal = (isBtnCloseClicked) => {
		setState((prvState) => ({
			...prvState,
			successMessage: null,
			succcsesTitle: null,
			errorImage: false,
			ctaTitle: null,
		}));
		if (isBtnCloseClicked) {
			closeCallBackRef.current?.();
		}
		closeCallBackRef.current = undefined;
	};
	return (
		<MessagesHandlerContext.Provider
			value={{
				setError: setErrorStateHandler,
				setSuccess: setMessageStateHandler,
			}}
		>
			<>
				{children}
				{state.errorMessage != null && (
					<ModalErrorMsg
						visibility={true}
						headerTitle={state.errorTitle ?? null}
						errorMessage={state.errorMessage}
						handleCloseModal={closeErrorModal}
						btnTitle={state.ctaTitle ?? 'OK'}
						isErrorImage={
							state.errorImage === undefined || !state.errorImage ? true : false
						}
					/>
				)}
				{state.successMessage != null && (
					<ModalCommonMessage
						show={true}
						title={state.succcsesTitle ?? ''}
						message={state.successMessage ?? ''}
						handleClose={closeSuccessModal}
						btnTitle={state.ctaTitle ?? 'OK'}
					/>
				)}
			</>
		</MessagesHandlerContext.Provider>
	);
};
export default ProviderMessagesHandler;

export const useMessagesHandler = () =>
	useContext(MessagesHandlerContext);
