import { defineChain } from 'viem';

export const VanarTestChain = defineChain({
  id: 1947,
  name: 'Vanar Testnet',
  network: 'Vanar',
  nativeCurrency: {
    decimals: 18,
    name: 'VANRY',
    symbol: 'VANRY',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.bimtvi.com'],
      webSocket: ['wss://rpc.bimtvi.com'],
    },
    public: {
      http: ['https://rpc.bimtvi.com'],
      webSocket: ['wss://rpc.bimtvi.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Explorer', url: 'https://explorer.bimtvi.com/' },
  },
});
// contracts: {
//   multicall3: {
//     address: '0xcA11bde05977b3631167028862bE2a173976CA11',
//     blockCreated: 5882,
//   },
// },
export const VanarVanGuardChain = defineChain({
  id: 78600,
  name: 'Vanar Vanguard',
  network: 'Vanar',
  nativeCurrency: {
    decimals: 18,
    name: 'VANRY',
    symbol: 'VANRY',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc-vanguard.vanarchain.com'],
      webSocket: ['wss://ws-vanguard.vanarchain.com'],
    },
    public: {
      http: ['https://rpc-vanguard.vanarchain.com'],
      webSocket: ['wss://ws-vanguard.vanarchain.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Vanguard Explorer', url: 'https://explorer-vanguard.vanarchain.com/' },
  },
});
export const VanarChainProd = defineChain({
  id: Number(process.env.REACT_APP_SUPPORTED_CHAIN),
  name: 'Vanar Mainnet',
  network: 'Vanar',
  nativeCurrency: {
    decimals: 18,
    name: 'VANRY',
    symbol: 'VANRY',
  },
  rpcUrls: {
    default: {
      http: [process.env.REACT_APP_RPC_VANAR],
      webSocket: ['wss://ws.vanarchain.com'],
    },
    public: {
      http: [process.env.REACT_APP_RPC_VANAR],
      webSocket: ["wss://ws.vanarchain.com"],
    },
  },
  blockExplorers: {
    default: { name: 'Vanar Explorer', url: process.env.REACT_APP_VANAR_EXPLORE_BASE},
  },
});