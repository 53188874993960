import React from 'react';
import useScrollToHash from '../../layouts/useScrollToHash';

const UserGuidelines = (props) => {
  useScrollToHash()
  return (
    <div className="content-page">
      <div className="container">
        <h1 className="main-title">Bazaa Marketplace User Guidelines</h1>
        <h5>Home Page</h5>
        <p>
          The Home Page provides a quick view of Bazaa’s latest collections and top sellers ranked
          in multiple chronological orders. It gives insight into what’s hot selling and the latest
          buying trends, keeping you up to speed with all the know-how in the world of NFTs and
          Blockchain technology.
        </p>

        <h5>Wallet Connection</h5>
        {/* <p>
          Before you explore the Bazaa Marketplace, we recommend you first attach a crypto wallet and shift it to Vanar Vanguard Net. Once attachment is complete, users can gain testnet Vanry via the Vanar Faucet <a href="https://faucet.vanarchain.com/" target="_blank" class="highlight">
            https://faucet.vanarchain.com/
          </a>
        </p> */}
        <p>
          Before exploring the Bazaa Marketplace, we recommend connecting a crypto wallet and
          switching it to Vanar Mainnet.
        </p>
        {/* <p>These testnet Vanry will come in handy in launching collections and NFTs.</p> */}

        <h5>Explore</h5>
        <p>
          Upon clicking "Explore", users can see all NFT Collections available on the Bazaa
          Marketplace. Users can filter through various categories, including:
        </p>
        <ul className="">
          <li>Art</li>
          <li>Collectibles</li>
          <li>Gaming</li>
          <li>Music</li>
          <li>PFPs</li>
        </ul>
        <p>
          Users can also use the “Search” option next to the Bazaa logo to search for specific
          items. The search will lead users to the nearest Collection/NFT that matches the
          description.
        </p>

        <h5>Collection Page</h5>
        <p>
          Clicking a Collection takes the user to a detailed view of that specific collection. Each
          collection page has a banner and the owner’s display picture. Information at the top
          includes Total Volume, Floor Price, Items Listed, Owners, and Date of Creation. The
          left-hand side displays all the metadata associated with that collection in drop-down
          form, making it easy to search for specific types of NFTs. Users can also view the status
          of each individual NFT, whether they are listed for display or up for sale. Filters on the
          right-hand side allow sorting by chronological order or price.
        </p>
        {/* <p>
          Below, the left hand side displays all the meta associated with that collection in
          drop-down form, so that it is easy for the user to search for any specific type of NFT
          within it. From here, the user can also view the status of each individual NFT, whether
          they are simply listed for display, or up for sale.
        </p>
        <p>
          The user can also use the filters provided on the right hand side to list the NFTs in two
          different orders, whether chronologically or according to price.
        </p> */}

        <h5>NFT Detail Page</h5>
        <p>
          Clicking any NFT within the Collections Page leads to its Detail Page, showing its
          Metadata, Creator, Current owner, and Ownership history. Users can either directly buy the
          item via “Buy Now” or add it to their cart.
        </p>

        <h5>Purchasing</h5>
        <p>
          Clicking "Buy Now" opens a pop-up of the connected wallet to confirm the transaction via
          wallet signature. Once purchased, you will be notified, and the item will be available in
          your inventory within a few minutes.
        </p>

        <h5>Profile Dropdown</h5>
        <p>
          In the top right corner of the home screen, between “Create” and the Dark Mode toggle, is
          the “Profile” drop-down. This drop-down displays the following options:
        </p>

        <ul>
          <li>
            <span className="tiles">My Profile Page:</span> Edit your personal banner and display
            picture. View the NFTs currently on sale from your account and the collections you have
            created. On the far right side of the page, click the Settings Icon to edit your
            Username and Biography. This page can also be accessed from the “Settings” option in the
            profile drop-down.
          </li>
          <li>
            <span className="tiles">Transaction History:</span> View all transactions conducted from
            your account, including assets minted, sold, and purchased, in a chronological order
            ranging from 1 to 30 days.
          </li>
          <li>
            <span className="tiles">Wallet Address:</span> View your wallet address in the profile
            drop-down, with options to copy or detach the wallet. Currently, we support Metamask,
            Coinbase, and WalletConnect.
          </li>
        </ul>

        {/* <h5>My Profile Page:</h5>
        <p>
          From here, you can edit your personal banner, as well as your display picture. You can
          also view the NFTs that are currently on sale from your account, as well as the
          Collections you have created.
        </p>
        <p>
          On the far right side of the page, you can click the Settings Icon to enter/edit your
          Username and Biography. You can also reach this page from the “Settings” option in the
          profile drop down.
        </p> */}

        {/* <h5>Transaction History:</h5>
        <p>
          Here, you can view all transactions conducted from your account, including assets minted,
          sold and purchased, within a chronological order ranging from 1 to 30 days.
        </p>

        <h5>Wallet Address:</h5>
        <p>
          You can view your wallet address in the profile drop down as well, giving you the option
          to either copy it, or detach the wallet. Currently we are supporting Metamask, Coinbase &
          Connect Wallet.
        </p> */}

        <h5>Create</h5>
        <p>
          From this page, you can create a new collection or add NFTs to your existing collections.
          Note: Before clicking “Single” to upload an NFT, you must first create a collection.
        </p>
        <ul>
          <li>
            <span className="tiles">Collection:</span> Create as many collections as you please.
            Note that a minimum of 6-10 $VANRY is required in your wallet to deploy a collection
            contract. You must add three types of images: a Logo (400x400px, max. 10Mb), a Featured
            Image (600x400px, max. 10Mb), and a Banner Image (1600x400px, max. 10Mb). Follow this
            with the Title, Symbol, Description, External link (if any), Payment token, Category,
            and your choice of royalties within a certain percentage (to a specific wallet address).
            Upon clicking submit, after a short wait, your collection will be created.
          </li>
          <li>
            <span className="tiles">Single (Adding NFTs to your collection):</span> On the left-hand
            side of this page, upload any kind of NFT, whether it is a static image, 3D model,
            video, or audio file. <span className="tiles">NOTE:</span> Failure to provide proof of
            ownership of copyrighted collections will result in their immediate removal.
            <p className="mt-2">
              {' '}
              Select the collection this NFT will be part of from the drop-down (NOTE: The drop-down
              requires you to have created at least one collection before uploading an NFT). Provide
              the NFT with a title and description, then select the Supply (number of mints), with a
              maximum Supply of one hundred (100). Each NFT has its own traits; provide specific
              traits to make it stand out or become part of a set. The right-hand side will
              continuously update to show the preview of your NFT and how it will be viewed by other
              users.
            </p>
          </li>
        </ul>

        <h5 id='erc721'>Contracts</h5>
        <h5>ERC-721</h5>
        <p>
          <span className="tiles">ERC-721</span> is a standard for non-fungible tokens (NFTs) on the
          Ethereum blockchain. Introduced by Dieter Shirley in 2017, it defines a protocol for
          creating unique, indivisible tokens. Each ERC-721 token has a distinct identifier, making
          it possible to differentiate between tokens, unlike fungible tokens such as
          cryptocurrencies, where each unit is identical. Key features include:
        </p>
        <ul>
          <li>
            <span className="tiles">Uniqueness:</span> Each token is distinct and can represent
            ownership of a specific item or asset.
          </li>
          <li>
            <span className="tiles">Indivisibility:</span> Tokens cannot be divided into smaller
            units.
          </li>
          <li>
            <span className="tiles">Ownership and Transfer:</span> Provides methods for token
            ownership and transfer, allowing for secure and verifiable transfers between parties.
          </li>
        </ul>
        <p>
          ERC-721 is widely used for applications like digital art, collectibles, and gaming assets,
          where uniqueness is essential.
        </p>
        <h5 id='erc1155'>ERC-1155</h5>
        <p>
          <span className="tiles">ERC-1155</span> is a multi-token standard introduced by Enjin in
          2018. It allows for the creation of both fungible and non-fungible tokens within a single
          contract, providing greater flexibility and efficiency. Key features include:
        </p>
        <ul>
          <li>
            <span className="tiles">Batch Operations:</span> Supports batch transfers and
            operations, significantly reducing transaction costs and complexity.
          </li>
          <li>
            <span className="tiles">Multi-Token Capability:</span> Enables the creation of multiple
            token types (fungible, non-fungible, and semi-fungible) within a single contract.
          </li>
          <li>
            <span className="tiles">Efficiency:</span> Reduces the need for multiple contracts and
            transactions, enhancing performance and scalability.
          </li>
        </ul>
        <p>
          ERC-1155 is particularly useful in gaming, where it can manage various in-game assets like
          currencies, items, and characters within a single contract, streamlining the development
          and user experience.
        </p>

        {/* <h5>Collection:</h5>
        <p>From here, you can create as many collections as you please.</p>
        <p>Note that a free of 0.25 Vanry applies for launching a collection.</p>
        <p>
          <strong>Note</strong> that the user must possess a minimum of 5-10 $VG in their wallet to
          deploy a collection contract.
        </p>
        <p>
          You must add three types of images, a Logo which can be 300x300px, a Featured Image of
          600x400px and a Banner Image of 1400x350px, followed by the Title, symbol, description,
          external link (if any), payment token, category and your choice of royalties within a
          certain percentage (to a specific wallet address).
        </p>
        <p>Upon clicking submit, after a short wait, your collection will be created.</p>

        <h5>Single (Adding NFTs to your collection)</h5>
        <p>
          On the left hand side of this page, you will be able to upload whatever kind of NFT you
          wish, whether it is a static image, a 3D model, a video or even an audio file.
        </p>
        <p>
          <strong>Note: </strong>Failure to provide proof of ownership of copyrighted collections
          will result in their immediate removal
        </p>
        <p>
          You must then select whichever collection this NFT will be a part of from the drop down (
          <strong>reminder:</strong> The drop down requires you to have created at least one
          collection before uploading an NFT).
        </p>
        <p>
          Provide the NFT with a title and description, and then select the Supply (number of mints)
          you wish to provide this NFT in. The maximum Supply for each individual NFT is limited to
          one hundred (100).
        </p>
        <p>
          As each NFT has its own traits, you must then provide that specific NFT traits, which can
          either make it stand out from the rest of the collection, or become part of a set of
          different NFTs.
        </p>
        <p>
          The right hand will continuously update to show you the preview of your NFT and how it
          will be viewed by the other users.
        </p> */}
      </div>
    </div>
  );
};

export default UserGuidelines;
