/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { STORE_ID } from './app/index';
import Home from './components/Home';
import CollectionListing from './components/CollectionListing';
import CollectionDetail from './components/CollectionDetail';
import NftDetail from './components/NftDetail';
import MyProfile from './components/MyProfile';
import Author from './components/Author';
import Notification from './components/Notification';
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import NotFound from './components/NotFound';
import StoreContext from './context/StoreContext';
import { fetchUserProfile } from './actions/profile_action';

import MainHeader from './components/headers/MainHeader';
import CreateSingleNftRoot from './components/create_nft/CreateSingleNftRoot';
import CreateNFTCollectionRoot from './components/create_nft/CreateNFTCollectionRoot';
// import Trading from './components/Trading';
import useWallets from './wallet-module/hooks/useWallets';
// import ProfileSettings from './themes/main/ProfileSettings';
import TransactionHistory from './components/TransactionHistory';
import PrivateRoute from './components/customs/protection/PrivateRoute';
import Footer from './themes/main/layouts/Footer';
import CreateNFTOptionsRoot from './components/create_nft/CreateNFTOptionsRoot';
import LoaderComponent from './components/customs/loader/LoaderComponent';
import { useDispatch } from 'react-redux';
import OwnerCollections from './components/OwnerCollections';
import PrivacyPolicy from './components/privacyPolicy';
import TermsAndCondition from './components/termsAndConditions';
import ProfileSettingRoot from './components/ProfileSettings/ProfileSettingRoot';
import UserGuidelines from './components/userGuidelines';
import TransferNft from './components/TransferNft';
import NftListing from './components/NftListing';
import PopularListing from './components/PopularListing';
import EachNftListing from './components/EachNftListing';
const App = () => {
  const [store] = useState(null);
  const [isCssJsLoaded, setIsCssJsLoaded] = useState(false);

  const dispatch = useDispatch();

  const { vanarAddressLowerCase } = useWallets();

  useEffect(() => {
    if (vanarAddressLowerCase) {
      dispatch(fetchUserProfile(vanarAddressLowerCase));
    }
  }, [dispatch, vanarAddressLowerCase]);
  const location = useLocation();
  const hasCollData = sessionStorage.getItem('imgData');

  if (location.pathname !== '/create-nft-collection' && hasCollData) {
    sessionStorage.removeItem('imgData')
  }

  // useEffect(() => {
  //   const loadScript = (src) => {
  //     return new Promise((resolve, reject) => {
  //       const script = document.createElement('script');
  //       script.src = src;
  //       script.async = true;
  //       script.onload = resolve;
  //       script.onerror = reject;
  //       document.body.appendChild(script);
  //     });
  //   };

  //   const updateTheme = async (store) => {
  //     // setStore((state) => ({ ...state, theme_id: themeID }));
  //     const themeAttributes = await import(`./themes/${store?.theme_id}/attributes.js`);

  //     // LOAD ALL CSS FILES
  //     const cssFiles = themeAttributes.CSS_FILES;
  //     cssFiles.forEach((cssFilePath) => {
  //       const link = document.createElement('link');
  //       link.rel = 'stylesheet';
  //       link.href = cssFilePath;
  //       document.head.appendChild(link);
  //     });

  //     // LOAD ALL JS FILES
  //     const jsFiles = themeAttributes.JS_FILES;

  //     let count = 0;

  //     for (const jsFilePath of jsFiles) {
  //       try {
  //         await loadScript(jsFilePath);
  //         count = count + 1;
  //       } catch (error) {
  //         console.error(`Error loading script: ${jsFilePath}`, error);
  //       }
  //     }

  //     if (themeAttributes.BODY_CLASSES) {
  //       themeAttributes.BODY_CLASSES.map((bc) => {
  //         window.addClassToElement('body', bc);
  //       });
  //     }

  //     if (count === jsFiles?.length) {
  //       setTimeout(() => {
  //         setIsCssJsLoaded(true);
  //       }, 1000);
  //     }
  //   };

  //   const fetchThemeConfig = async () => {
  //     try {
  //       const store = (await Common.getSingleDocument(`/stores`, STORE_ID)) ?? {};

  //       store['theme_id'] = 'main';
  //       setStore(store);
  //       // localStorage.setItem('theme_id', store.theme_id);
  //       updateTheme(store);
  //     } catch (error) {
  //       console.log('Error fetching theme config:', error);
  //     }
  //   };

  //   // updateTheme();
  //   fetchThemeConfig();
  //   // window.scrollTo(0, 0);
  // }, []);

  // Function to check dark mode status from local storage
  const checkDarkMode = () => {
    // Retrieve dark mode status from local storage
    const isDarkModeEnabled = localStorage.getItem('darkMode') === 'true';

    // Apply dark mode if it was enabled
    if (isDarkModeEnabled) {
      document.body.classList.add('dark-scheme');
    }
    if (!isDarkModeEnabled) {
      if (document.body.classList.contains('dark-scheme')) {
        document.body.classList.remove('dark-scheme');
      }
    }
  };


   document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      checkDarkMode();
    }
  });

  useEffect(() => {
    checkDarkMode();
  }, []);



  useEffect(() => {
    setTimeout(() => setIsCssJsLoaded(true), 1500);
  }, []);

  if (!isCssJsLoaded) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <StoreContext.Provider value={{ ...store }}>
      <MainHeader />
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="collections" element={<CollectionListing />} />
          <Route path="transfer" element={<TransferNft />} />
          <Route path="nfts" element={<EachNftListing/>} />
          <Route path="owner-collections/:owner_id" element={<OwnerCollections />} />
          <Route path="collection/:code/:tokenId" element={<NftDetail />} />
          <Route path="collection/:code" element={<CollectionDetail />} />
          <Route path="create-nft-options" element={<CreateNFTOptionsRoot />} />
          <Route
            path="create-nft-collection"
            element={<PrivateRoute component={CreateNFTCollectionRoot} />}
          />
          <Route
            path="create-single-nft"
            element={<PrivateRoute component={CreateSingleNftRoot} />}
          />

          <Route path="my-profile" element={<MyProfile />} />
          <Route path="transactions" element={<TransactionHistory />} />
          {/* <Route path="trading/:code" element={<Trading />} /> */}
          <Route path="user/:username" element={<Author />} />
          <Route path="notifications" element={<Notification />} />
          <Route path="my-profile/settings" element={<ProfileSettingRoot />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="user-guidelines" element={<UserGuidelines />} />
          <Route path="terms" element={<TermsAndCondition />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/popular" element={<PopularListing/>} />
        </Route>
      </Routes>
      {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}> */}
      <Footer />
      {/* </GoogleReCaptchaProvider> */}
    </StoreContext.Provider>
  );
};

export default App;
