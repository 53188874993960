import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { cross } from '../assets';

const ModalCommonMessage = (props) => {
  const { handleClose, show, message, title, btnTitle } = props;

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="w-540"
      contentClassName="border-box top d-flex align-items-center justify-content-center p-3"
      show={show}
      centered
    >
      {/* <Modal.Header className="border-0" closeButton>
        <Modal.Title>Alert!</Modal.Title>
      </Modal.Header> */}
      <button
        className="btn-close ml-auto"
        onClick={() => {
          handleClose(false);
        }}
      ></button>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className="modal-title text-center bg-transparent">
          <h3 className="title md text-capitalize mb-5 bg-transparent">{title}</h3>
          <p className="mb-0 mt-3">{message}</p>
        </div>

        <Button
          style={{ width: 200, height: 40 }}
          className="custom-btn lg primary mt-3"
          onClick={() => {
            handleClose(true);
          }}
        >
          {btnTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCommonMessage;
