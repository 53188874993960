import { combineReducers } from 'redux';
import profileReducer from './profile_reducer';
import marketplaceListingReducer from './marketplace_reducer';
import marketplaceFilterReducer from './marketplace_filters';
import marketplaceNftsListingQueryReducer from './nfts_listing_query';
import nftsListingQueryReducer from './nft_listing_query_reducer'
import nftsListingReducer from './nfts_listing_reducer';
import cartReducer from './cart_reducer';
import vcoinsReducer from './vcoins_reducer';
import transactionhistory_reducer from './transactionhistory_reducer';
import nfts_listing_filters from './nfts_listing_filters';
import ProfileListingQueryReducer from './profile_listing_reducer';
import collection_analytics_reducer from './collection_analytics_reducer';
import marketplaceCollectionListingQueryReducer from './collection_listing_query';
import marketplaceCollectionListingReducer from './collection_listing_reducer';
import PopularListingQueryReducer from './PopularListingQueryReducer';

const rootReducer = combineReducers({
  profile: profileReducer,
  nfts_listing_filters: nfts_listing_filters,
  marketplace_listing: marketplaceListingReducer,
  marketplace_filters: marketplaceFilterReducer,
  nfts_listing_query: nftsListingQueryReducer,
  nfts_listing: nftsListingReducer,
  collection_analytics: collection_analytics_reducer,
  collection_listing_query: marketplaceCollectionListingQueryReducer,
  collection_listing: marketplaceCollectionListingReducer,
  cart_assets: cartReducer,
  vcoins: vcoinsReducer,
  transactionhistory: transactionhistory_reducer,
  profile_nft_listing_query: ProfileListingQueryReducer,
  popular_listing_query: PopularListingQueryReducer

});

export default rootReducer;
