import React from 'react';
import './loader.css';
import { appLogoSmall } from '../../../assets/images';
import { Spinner } from 'react-bootstrap';

function LoaderAnimated({size}) {
  return <Spinner src={appLogoSmall} alt="" className="rotating-image" size={size ?? ''}/>;
}

export default LoaderAnimated;
