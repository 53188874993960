let initialState = {
  nfts: [],
  nft_list: [],
  filters: {},
  total: 0,
  is_loading: true,
  load_more: false,
};

const nftsListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NFTS_LISTING':
      return {
        ...state,
        nfts: action.payload.nfts,
        total: action.payload.total,
      };
    case 'ADD_NFTS':
      return {
        ...state,
        nfts: [...state.nfts, ...action.payload.nfts],
        total: action.payload.total,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        is_loading: action.is_loading,
      };
    case 'TOGGLE_LOAD_MORE_LOADING':
      return {
        ...state,
        load_more: action.load_more,
      };
    case 'NEW_ITEMS':
      return {
        ...state,
        nft_list: action.payload.data,
        is_loading: action.is_loading,
      };
    // case 'NFT_LISTING_FILTERS':
    //   return {
    //     ...state,
    //     filters: action.payload.filters,
    //   };
    default:
      return state;
  }
};

export default nftsListingReducer;
