import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDynamicComponent from './useDynamicComponent';
import {
  fetchCategories,
  //fetchLatestCollections,
  fetchFeaturedCollections, fetchTopCollection,
  fetchTrendingCollection,
} from '../actions/marketplace_action';
import LoaderComponent from './customs/loader/LoaderComponent';

const Home = (props) => {
  const HomeComponent = useDynamicComponent('Home');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeaturedCollections({is_featured:true}));
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     fetchTrendingCollection({
  //       is_trending: true,
  //     }),
  //   );
  // }, []);

  // useEffect(() => {
  //   dispatch(
  //       fetchTopCollection({
  //         is_top_loading: true
  //       }),
  //   );
  // }, []);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (!HomeComponent) {
    return <LoaderComponent />;
  }

  return (
    <>
      <HomeComponent />
    </>
  );
};

export default Home;
