import React, { memo, useCallback, useContext } from 'react'
import StoreContext from '../../context/StoreContext';
import { useNavigate } from 'react-router-dom';
import useDynamicComponent from '../useDynamicComponent';
import LoaderComponent from '../customs/loader/LoaderComponent';
const CreateNFTOptionsRoot = () => {

    const { store } = useContext(StoreContext);
    const navigate = useNavigate()
    const handleBtnsClick = useCallback((event) => {

        if (event.target.id === 'Create-Single') {
            navigate('/create-single-nft')
        }
        else if (event.target.id === 'Create-multiple') {
            navigate('/create-multiple-nft')
        }

    }, []);


    const CreateNFTOptions = useDynamicComponent('create_nft/CreateNFTOptions', store)

    if (!CreateNFTOptions) {
        return (
            <div id="wrapper">
              <LoaderComponent />
            </div>
          );
    }


    return (<>
        <CreateNFTOptions handleBtnsClick={handleBtnsClick} />
    </>)

}

export default memo(CreateNFTOptionsRoot)


