import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-page">
      <h1 className="mt-5">404</h1>
      <Link to="/">Go to Home</Link>
    </div>
  );
};

export default NotFound;
