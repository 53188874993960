import { STORE_ID } from '../app/index';
import Common from '../helpers/common.helper';

export const addCartAssets = (cart_assets) => ({
  type: 'SET_CART_ASSETS',
  cart_assets,
});

export const fetchCartAssets = (userId) => {
  return (dispatch) => {
    return Common.getAllDocuments(
      `/stores/${STORE_ID}/cart_assets`,
      [
        {
          key: 'user_id',
          op: '==',
          value: userId,
        },
      ],
      null,
    )
      .then((response) => {
        dispatch(addCartAssets(response.docs));
      })
      .catch((error) => {
        dispatch(addCartAssets([]));
      });
  };
};
