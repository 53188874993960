import { GCP_API_URL, REACT_APP_ASSET_METADATA_BUCKET, REACT_APP_CDN_BUCKET } from '../app/index';
import lodash from 'lodash';
import Service from '../app/service';
import Common from './common.helper';
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
const NFT_METADATA = {
  description: '',
  external_url: '',
  image: '',
  name: '',
  attributes: [
    // {
    //   trait_type: 'ip',
    //   value: 'Virtua',
    // },
  ],
};
export const dataURLtoBlob = (dataURL) => {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const byteString = atob(parts[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: contentType });
};

export const getAnimationThumbnail = async (fileExt, refMP4, refGLB, executeRecaptcha) => {
  if (fileExt?.toLowerCase() === 'glb' || fileExt?.toLowerCase() === 'gltf') {
    const thumb = dataURLtoBlob(refGLB.current.toDataURL());
    thumb.name = 'thumb_' + new Date().getTime().toString().slice(7) + '.png';
    const token = await executeRecaptcha('CreateThumb');
    return await uploadNFTImage2(thumb, 'nft_images', token);
  }
  if (fileExt?.toLowerCase() === 'mp4') {
    try {
      const video = refMP4.current;
      const canvas = document.createElement('canvas');
      canvas.width =
        video.videoWidth ?? video.offsetWidth ?? video.clientWidth ?? video.width ?? 512;
      canvas.height =
        video.videoHeight ?? video.offsetHeight ?? video.clientHeight ?? video.height ?? 512;

      if (canvas.width > 512) {
        canvas.height = (512 / canvas.width) * canvas.height;
        canvas.width = 512;
      }
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnailDataUrl = canvas.toDataURL('image/png');
      const thumb = dataURLtoBlob(thumbnailDataUrl);
      thumb.name = 'thumb_' + new Date().getTime().toString().slice(7) + '.png';
      const token = await executeRecaptcha('CreateThumb');
      return await uploadNFTImage2(thumb, 'nft_images', token);
    } catch (error) {
      console.log('error thumbnail', error);
    }
  }
};
export function createThumbnailFile(file, fileExt, maxWidth, maxHeight, filename) {
  if (['mp4', 'glb', 'gltf'].includes(fileExt?.toLowerCase())) {
    return null;
  }
  return new Promise((resolve, reject) => {
    if (!file) {
      return null;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = image.width;
        let height = image.height;

        // Calculate thumbnail dimensions
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          const thumbnailFile = new File([blob], filename, { type: file.type });
          resolve(thumbnailFile);
        }, file.type);
      };

      image.onerror = (error) => {
        reject(error);
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}
export const uploadNFTImage2 = async (data, dir_name, token) => {
  let idToken = await Common.getFirebaseAccessToken();
  const formData = new FormData();
  formData.append('filename', data?.name ?? uuidV4());
  formData.append('directory', dir_name);
  formData.append('token', token);
  try {
    const response = await Service.postCall(GCP_API_URL + 'getSignedUrl', formData, {
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
    });
    const { signedUrl, fileUrl, contentType, message } = response.data;
    // Upload file to Google Cloud Storage using signed URL
    if (signedUrl && signedUrl.length > 0) {
      const uploadResponse = await axios.put(signedUrl, data, {
        headers: {
          'Content-Type': data.type === '' ? contentType : data.type,
        },
      });
      if (uploadResponse.status === 200) {
        return fileUrl;
      } else {
        throw Error('Failed to upload. Please try again later.');
      }
    } else {
      throw Error(message ?? 'Failed to upload. Please try again later.');
    }
  } catch (error) {
    throw Error(
      error?.response?.data?.message ??
        error?.message ??
        'Failed to upload. Please try again later.',
    );
  }
};
export const uploadCollectionImages = async (image, executeRecaptcha) => {
  if (typeof image == 'object' && image?.length > 0) {
    const token = await executeRecaptcha('CreateCollectionImages');
    const url = await uploadNFTImage2(image[0], 'collection_images', token);
    return url;
  }
  if (typeof image === 'string' && image?.startsWith('http')) return image;
  return '';
};
export const uploadNFTMetaData = async (
  tokenID,
  contractAddr,
  data,
  attributes,
  imageFilePath,
  contractSelected,
  thumbnail,
) => {
  const nftMeta = lodash.cloneDeep(NFT_METADATA);

  nftMeta.description = data.nft_desc ?? data.description;
  nftMeta.external_url = '';
  nftMeta.name = data.nft_name;
  nftMeta.image = thumbnail ?? imageFilePath;
  nftMeta.animation_url = thumbnail && imageFilePath;
  nftMeta.attributes = attributes;

  let idToken = await Common.getFirebaseAccessToken();
  const fileResData = await Service.postCall(
    GCP_API_URL + 'uploadMetaDataFile',
    {
      bucket: REACT_APP_ASSET_METADATA_BUCKET,
      directory: contractSelected?.code ?? contractAddr,
      filename: `${tokenID}`,
      nftMeta: JSON.stringify(nftMeta),
    },
    {
      headers: {
        Authorization: idToken,
      },
    },
  );
  return fileResData?.data?.url;
};
export const makeAndUploadNFTMetadata = async (data) => {
  let idToken = await Common.getFirebaseAccessToken();
  const formData = new FormData();
  formData.append('image', data.nft_img['0']);
  const imgUploadRes = await Service.postCall(GCP_API_URL + 'uploadImageFile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: idToken,
    },
  });

  console.log('imgUploadRes:::', imgUploadRes);
};
