let initialState = {
  filters: {},
};

const nftsListingFiltersReducer = (state = initialState, action) => {
  //console.log("action",action);
  switch (action.type) {
    case 'NFT_LISTING_FILTERS':
      return {
        ...state,
        filters: action.payload.filters,
      };
    default:
      return state;
  }
};

export default nftsListingFiltersReducer;
