let initialState = {
  limit:24,
  page:1,
  sortAt:"amount",
  sortTerm:"asc",
  filter:[],
  contractAddr:'',
  status:[],
  amount:[],
  type:'',
  creatorBy:'',
  ownerId:'',
  searchTerm:''
 };
 
 const nftsListingQueryReducer = (state = { ...initialState }, action) => {
   switch (action.type) {
     case 'SET_NFTS_LISTING_FILTERS':
       return {...state,...action.filters};
     case 'RESET_NFTS_LISTING_FILTERS': {
       return {
        limit:24,
        page:1,
        sortAt:"amount",
        sortTerm:"asc",
        filter:[],
        contractAddr:'',
        status:[],
        amount:[],
        type:'',
        creatorId:'',
        ownerId:'',
        searchTerm:''
       };
     }
     default:
       return state;
   }
 };
 
 export default nftsListingQueryReducer;
 