import { Modal } from 'react-bootstrap';
import React from 'react';
import Common from '../../../../helpers/common.helper';

const ModalConnectSocial = (props) => {
  const {
    isConnectSocial,
    setIsConnectSocial,
    service,
    vanarAddressLowerCase,
    index,
    setLoadingIndex,
    collectionData
  } = props;

  const handleConnect = async () => {
    setLoadingIndex(index);
    localStorage.setItem('isConnecting', true);
    localStorage.setItem('loadingIndex', index);
    await Common.connectSocials(service, vanarAddressLowerCase, window?.location?.href,collectionData);
  };

  const handleCross = () => {
    setIsConnectSocial(false);
    setLoadingIndex(null);
    localStorage.removeItem('loadingIndex');

  }
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="w-600 modal-dialog-centered"
      contentClassName="border-box top disconnect-modal"
      show={isConnectSocial}
      onHide={() => {
        setIsConnectSocial(false);
        setLoadingIndex(null);
      }}
    >
      <Modal.Header className="border-0" onClick={handleCross} closeButton></Modal.Header>
      <h3 className="text-center pt-3">Please ensure you are logged into your social profile before connecting your accounts</h3>

      <div className="d-flex gap-4 pt-3 disconnect-flex">
        <button className="btn-main w-100 add-btn " onClick={handleConnect}>PROCEED</button>
      </div>
    </Modal>
  );
};
export default ModalConnectSocial;
