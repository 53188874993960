import axios from 'axios';
import { STORE_ID } from './index';

export default class Service {
  static access_token = null;

  static getCall(url) {
    return axios.get(url, this.getHeaders());
  }

  static postCall(url, body, headers = null) {
    // if(headers){
    //   headers.headers.withCredentials = true
    // }
    return axios.post(url, body, headers ? headers : this.getHeaders());
  }

  static putCall(url, body) {
    return axios.put(url, body, this.getHeaders());
  }

  static delCall(url) {
    return axios.delete(url, this.getHeaders());
  }

  static getHeaders() {
    const headers = {
      'Content-Type': 'application/json',

    };
    // headers.withCredentials = true
    if (this.access_token) {
      headers.Authorization = this.access_token;
    }

    return { headers };
  }

  static setAccessToken(token) {
    this.access_token = token;
  }
}
