import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';

import LoaderComponent from './customs/loader/LoaderComponent';
const TransactionHistory = (props) => {
  const { store } = useContext(StoreContext);
  
  const TransactionComponent = useDynamicComponent('transaction-history/Transaction', store);
  
  if (!TransactionComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <TransactionComponent />
    </>
  );
};

export default TransactionHistory;
