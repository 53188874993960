// ThemeContext.js
import { createContext, useState } from 'react';

const StoreContext = createContext({
  errorMessage: null,
  setErrorMessage: (isVisible) => {},
  setIsDisconnecting: (isConnecting) => {},
  isDisconnecting: false,
  supportedChainID: -1,
});

export default StoreContext;
