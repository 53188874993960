import { Modal } from 'react-bootstrap';
import React from 'react';
import Common from '../../../../helpers/common.helper';

const ModalDisconnectSocial = (props) => {
  const {
    isDisconnectSocial,
    setIsDisconnectSocial,
    socialLogin,
    setSocialLogin,
    connectedService,
  } = props;
  const disconnectSocial = async () => {
    const newSocials = await Common.disconnectSocial(connectedService, socialLogin, setSocialLogin);
    setIsDisconnectSocial(false)
    setSocialLogin(newSocials);

  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="w-600 modal-dialog-centered"
      contentClassName="border-box top disconnect-modal"
      show={isDisconnectSocial}
      onHide={() => {
        setIsDisconnectSocial(false);
      }}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <h3 className="text-center pt-3">Are you sure you want to disconnect?</h3>

      <div className="d-flex gap-4 pt-3 disconnect-flex">
        <button className="btn-main w-50 add-btn " onClick={disconnectSocial}>YES</button>
        <button
          className="btn-main w-50 add-btn"
          onClick={() => {
            setIsDisconnectSocial(false);
          }}
        >
          NO
        </button>
      </div>
    </Modal>
  );
};
export default ModalDisconnectSocial;
