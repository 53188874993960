import React from 'react';

const PrivacyPolicy = (props) => {
  return (
    <div className="content-page">
      <div className="container">
        <h1 className="main-title">Bazaa Marketplace Privacy Policy</h1>
        <h5>Last Updated: January 1, 2024</h5>
        <p>
          Bazaa Marketplace (“Bazaa,” “we”, “us”, or “our”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the information we collect, use, and share in connection with the Bazaa Marketplace websites, mobile apps, and other services we and our affiliates provide to you (collectively, the "Service").
        </p>

        <h5>1. Types of Information We Collect</h5>
        <p>We collect information about our users, as described below</p>
        <h6>a. Information You Provide Us:</h6>
        <ul>
          <li className='mb-3'>
            <strong>Account Information:</strong> When you create an account or use our Service, you provide us with certain information. We collect this information and other data you add to your account, such as email address, username, linked social media accounts, favorited items, watchlisted collections, and other information you provide. Your Account Information (other than your email address) will be publicly visible. Remember, public content can exist elsewhere on the internet even after you remove it from your account on Bazaa.
          </li>
          <li className='mb-3'>
            <strong>Verification Information:</strong> We may need to collect information to verify your account or identity, such as your phone number, email address, or information relating to the authentication app you use.
          </li>
          <li className="mb-3">
            <strong>Preferences:</strong> Our Service lets you store preferences like how your content is displayed, notification settings, and favorites. We may associate these choices with your account, browser, and/or mobile device.
          </li>
          <li className='mb-3'>
            <strong>Feedback:</strong> If you provide us with feedback or contact us, we may receive your name and contact information (such as an email address), as well as any other content included in or associated with the message you send.
          </li>
          <li>
            <strong>Other Information:</strong> We also collect information and other data at other points in our Service where you voluntarily provide it or where we state that your information is being collected.
          </li>
        </ul>
        <p>
          Additionally, like much of web3, your blockchain wallet address functions as your identity on Bazaa Marketplace. Accordingly, you will need a blockchain wallet and associated wallet address to access certain aspects of the Service. We do not consider a blockchain wallet address, standing alone, to be information that identifies you. However, a blockchain wallet address may become associated with you, your user ID or information we collect about you when you use our Service.
        </p>

        <h6>b. Additional Information Collected:</h6>
        <ul>
          <li className="mb-3">
            <strong>Interactions with our Service:</strong> To provide our Service, analyze trends, enforce our Terms of Service, and make the Service more useful to you, we collect information (typically Anonymous Data) from you when you interact with our Service, including, but not limited to, your browser type or fingerprint, operating system, IP address and associated geolocation, device ID, blockchain wallet address, wallet type, actions and clickstream data, referring/exit pages, and date/time stamps. We may also store this data in log files.
          </li>
          <li className="mb-3">
            <strong>Cookies or Other Tracking Technologies:</strong> Like many online services, we use cookies to collect information. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them) to analyze how users interact with our Service, make improvements to our product quality, and provide users with a more personalized experience. In addition, we use "Pixel Tags" (also referred to as clear Gifs, Web beacons, or Web bugs). Pixel Tags allow us to analyze how users find our Service, make the Service more useful to you, and tailor your experience with us to meet your particular interests and needs.
          </li>
          <li>
            <strong>Do Not Track:</strong> We do not specifically respond to "Do Not Track" signals.
            We engage with third-party services ("Third Party Services"), including Google and Amplitude, to help collect some of the information referred to above. These Third Party Services, acting on our behalf, may collect information about your use of our Service through their own cookies, Pixel Tags, or other technologies ("Usage Information"). The Third Party Services’ ability to use and share Usage Information is restricted by such Third Party Services’ terms of use and privacy policy.
          </li>
        </ul>

        <h6>c. Information Collected from Third-Party Companies</h6>
        <p>
          We may receive information about you or related to you or your wallet address from service providers and other sources/companies that offer their products and/or services to us or to you, for use in conjunction with our Service, or whose products and/or services may be linked from our Service. We may add this to the data we have already collected from or about you through our Service. This information may include, for example:
        </p>

        <ul>
          <li className='mb-3'>third-party wallet providers and partners that provide us with your blockchain wallet address and certain other information you choose to share with those wallet</li>
          <li className="mb-3">
            data analytics providers or vendors who provide us information related to certain blockchain wallet addresses;
          </li>
          <li className="mb-3">
            vendors who provide communication and identity verification services to us and which you choose to use; and
          </li>
          <li>
            other vendors who provide us information necessary to run our business or enforce our Terms of Service.
          </li>
        </ul>

        <h6>d. Public Information</h6>
        <p>
          We collect data from activity and information that is publicly visible and/or accessible on blockchains or other public sources. This may include, for example, blockchain wallet addresses and information regarding purchases, sales, or transfers of NFTs, which may then be associated with other data you have provided to us.
        </p>

        <h5>2. Use of Your Information</h5>
        <p><strong>a.</strong> We process information about and/or related to you to run our business, provide the Service, personalize your experience on the Service, and improve the Service. Specifically, we use your information to:</p>

        <ul>
          <li className="mb-3">
            provide, operate and maintain the Service
          </li>
          <li className="mb-3">
            improve and analyze the Service;
          </li>
          <li className='mb-3'>
            analyze, improve, and personalize your use and experience on the Service, including by making recommendations to you;
          </li>
          <li className="mb-3">
            communicate with you;
          </li>
          <li className="mb-3">
            maintain the safety, security and integrity of our Service, and investigate, address, and prevent conduct that may violate our Terms of Service and/or that is otherwise harmful or unlawful;
          </li>
          <li className="mb-3">
            send you newsletters, promotional materials, and other notices related to our Service or third parties’ goods and services;
          </li>
          <li className="mb-3">
            comply with applicable laws, cooperate with investigations by law enforcement or other authorities of suspected violations of law, and/or to protect our and our Affiliates’ legal rights; and
          </li>
          <li>
            act in any other way which we have communicated to you and to which you have consented or we may describe when you provide your information.
          </li>
        </ul>

        <p>
          <strong>c.</strong> We may create anonymized, de-identified, or aggregated records from identifiable information. We use this data for the same purposes as outlined above, including to improve our Service, and may also use and/or disclose such anonymized, de-identified, or aggregated information for any purpose. We will maintain and use anonymized and de-identified data in anonymized/de-identified form and not attempt to re-identify the information unless permitted by law.
        </p>

        <p>
          If you are a resident in the EEA/UK, please refer to Section 12, which details the legal basis we rely on for these processing activities.
        </p>

        <h5>3. Disclosure of Your Information</h5>
        <p>We disclose your information and information about you as described below:</p>
        <h6>a. Third Party Service Providers</h6>
        <p>
          We may share your information and information about you with third party service providers to: provide technical infrastructure services; conduct quality assurance testing; analyze how our Service is used; prevent, detect, and respond to unauthorized activities or potential violations of our Terms of Service or policies; identity verification purposes; provide technical and customer support; and/or to provide other support to you, us, and/or to the Service.
        </p>

        <h6>b. Affiliates</h6>
        <p>
          In order to provide our Services in accordance with our contract with you and in our legitimate interests to run an efficient business, we may share some or all of your information and information about you with any subsidiaries, joint ventures, or other companies or products under our common control ("Affiliates"), in which case we will require our Affiliates to honor this Privacy Policy.
        </p>

        <h6>c. Information Related to Your Public Activity</h6>
        <p>
          In order to provide our Services in accordance with our contract with you, we may display or share information relating to your public activity on blockchains, Bazaa Website, and/or Bazaa Marketplace. For example, we use technology like APIs to make certain information like your blockchain activity available to websites, apps, and others for their use.
        </p>

        <h6>d. Corporate Restructuring</h6>
        <p>
          In our legitimate interests to run an effective business, we may share some or all of your information and information about you in connection with or during negotiation of any merger, financing, acquisition, or dissolution transaction or proceeding involving a sale, transfer, or divestiture of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, your information and information about you may also be transferred as a business asset. If another company acquires our company, business, or assets, that company will possess the information collected by us and will assume the rights and obligations regarding your information and information about you as described in this Privacy Policy.
        </p>

        <h6>e. Legal Rights</h6>
        <p>
          In our legitimate interests to run an effective business and/or as required by law, regardless of any choices you make regarding your information (as described below), Bazaa may disclose your information and information about you if it believes in good faith that such disclosure is necessary: (i) in connection with any legal investigation; (ii) to comply with relevant laws or to respond to subpoenas, warrants, or other legal process served on Bazaa; (iii) to protect or defend the rights or property of Bazaa or users of the Service; and/or (iv) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Service.
        </p>

        <h6>f. Other Disclosures</h6>
        <p>
          We may also disclose your information and information about you: to fulfill the purpose for which you provide it, including to provide you with our Service and new features or facilitate the use of login integrations in accordance with our contract with you; with our professional advisors in our legitimate interests to run an effective and successful business; for any other purpose disclosed by us when you provide it; or with your consent.
        </p>

        <h5>4. Third-Party Websites</h5>
        <p>
          Our Service may contain links to third-party websites. When you click on a link to any other website or location, you will leave our Service and go to another site, and another entity may collect information from you. We have no control over and cannot be responsible for these third-party websites or their content. Please be aware that this Privacy Policy does not apply to these third-party websites or their content, or to any collection of your information or information about you after you click on links to such third-party websites. We encourage you to read the privacy policies of every website you visit. Any links to third-party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content, or websites.
        </p>

        <h5>5. Third-Party Wallets</h5>
        <p>
          To use certain aspects of our Service, you must use a wallet which allows you to engage in transactions on public blockchains. Your interactions with any third-party wallet provider are governed by the applicable terms of service and privacy policy of that third party.
        </p>

        <h5>6. Your Choices Regarding Information</h5>
        <p>You have choices regarding the use of information on our Service:</p>

        <h6>a. Email Communications</h6>
        <p>
          We may periodically send you newsletters and/or emails that directly promote the use of our Service or third parties’ goods and services. When you receive email communications from us, you may indicate a preference to stop receiving these communications from us by following the unsubscribe instructions provided in the email you receive or through the Notifications preferences in your Settings page. Despite these preferences, we may send you occasional transactional service-related informational communications.
        </p>

        <h6>b. Cookies</h6>
        <p>
          If you decide at any time that you no longer wish to accept cookies from our Service for any of the purposes described above, you can change your browser settings to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Consult your browser’s technical information to learn more. If you do not accept cookies, however, you may not be able to use all portions of the Service or all functionality of the Service.
        </p>

        <h5>7. Data Access and Control</h5>
        <p>
          You can view, access, edit, or delete your information and information about you for certain aspects of the Service via your Settings page. Depending on applicable law where you reside, you may have certain rights in relation to information about you. However, such rights are not absolute and may apply only in certain circumstances. If such rights are not provided under law for your operating entity or jurisdiction, Bazaa has full discretion in fulfilling your request.
        </p>

        <p>
          <strong>a.</strong> Rights available may include the right to (i) request access and obtain a copy of your personal information, or certain details regarding the ways in which we use and disclose your personal information; (ii) request correction/rectification of inaccurate personal information that we hold about you; (iii) request deletion of your personal information; (iv) object to or restrict the processing of your personal information; (v) request portability of your personal information and (vi), if we have collected and processed your personal information based on your consent, you may have the right to withdraw your consent at any time.
        </p>

        <p> <strong>b. </strong>
          If you wish to exercise your rights under an applicable data protection or privacy law, please contact us by sending an email to <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a> or at the address provided in Section 15 below, specify your request, and reference the applicable law. We may ask for additional information to verify your identity (such as your email address or government issued ID) or ask for more information about your request. We will consider and act upon any above request in accordance with applicable law. You may designate an authorized agent to make requests on your behalf to exercise your rights, but before accepting such a request from an agent, we will require them to provide proof you have authorized them to act on your behalf and may need you to verify your identity directly with us. We will not discriminate against you for exercising any of these rights.
        </p>

        <p><strong>c. </strong>
          If you believe that we have infringed your rights, we encourage you to first contact us by sending an email to <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a> so that we can try to resolve the issue or dispute informally. If we deny your request, you may have the right to appeal our decision by sending an email to <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a>). While we hope you are satisfied with our responses, you also have the right to complain to the data protection regulator where you live or work.
        </p>

        <h5>8. Data Retention</h5>
        <p>
          We may continue to retain your information or information about you even after you request deletion of your data if such retention is reasonably necessary to comply with our legal obligations, to resolve disputes, prevent fraud and abuse, enforce our Terms or other agreements, and/or protect our legal rights and other interests. We otherwise retain information for as long as is reasonably necessary for the purposes specified in this Privacy Policy. In determining the length of time, we consider criteria such as whether we need the information to continue providing our Services, comply with legal obligations, and other factors set out in this section.
        </p>

        <h5>9. Security</h5>
        <p>
          We care about the security of your information and use physical, administrative, and technological safeguards to preserve the integrity and security of information collected through our Service. However, no security system is impenetrable and we cannot guarantee the security of our systems, or those of our vendors. In the event that any information under our custody and control is compromised as a result of a breach of our security, we will take steps to investigate and remediate the situation and, in accordance with applicable laws and regulations, may notify those individuals whose information may have been compromised. You are responsible for the security of your digital wallet, and we urge you to take steps to ensure it is and remains secure. If you discover an issue related to your wallet, please contact your wallet provider.
        </p>

        <h5>10. Minors</h5>
        <p>
          We do not intentionally gather information from visitors who are under the age of 13 (or under the age of 16 for individuals in the EEA/UK). Our Terms of Service require all users to be at least 18 years old. Minors who are at least 13 years old (or at least 16 for individuals in the EEA/UK) but are under 18 years old may use a parent or guardian’s account, but only with the supervision of the account holder. If a child under 13 (or under 16 for individuals in the EEA/UK) submits identifiable information to us and we learn that the identifiable information is the information of a child under 13 (or under 16 for individuals in the EEA/UK), we will attempt to delete the information as soon as possible. If you believe that we might have any identifiable information from a child under 13 (or under 16 for individuals in the EEA/UK), please contact us by sending an email to <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a> or at the address indicated in Section 15 below.
        </p>

        <h5>11. Users Outside of the United States</h5>
        <p>
          If you are a non-U.S. user of the Service, by visiting the Service and providing us with information directly or indirectly, you understand and acknowledge that your information and information about you may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of your information or information about you may be less stringent than the laws in your country.
        </p>

        <h5>12. For users in EEA, UK and Switzerland</h5>
        <p>
          For the purposes of the EU and UK General Data Protection Regulation, Ozone Networks, Inc. is the controller and our contact details are set out in Section 15 below.
        </p>

        <p>
          <strong>Legal Bases for Processing Personal Data.</strong> When you access or use the Service, we collect, use, share, and otherwise process your personal data for the purposes described in this Privacy Policy. We rely on a number of legal bases to use your information in these ways.
        </p>
        <p> <strong>a. </strong>
          We process your personal data with your consent, for example, to: communicate with you; send you marketing emails and/or notifications; and for any other purpose that we communicate to you and to which you have consented.
        </p>

        <p><strong>b. </strong>
          We process your personal data in order to fulfill our contract with you and to provide you with our Service, for example, to: provide, operate and maintain the Service; and investigate, address, and prevent conduct that may violate our Terms of Service.
        </p>
        <p><strong>c. </strong>
          We process your personal data pursuant to our legitimate interests to provide effective services to you and maintain our business relationship, for example, to: improve and analyze the Service; and personalize your experience on the Service.
        </p>
        <p><strong>d. </strong>
          We process your personal data in order to comply with legal obligations, in the public interest, or in your vital interests, for example, to: detect, prevent, and address activities that we consider could be fraudulent, violations of our Terms of Service or policies, and/or otherwise harmful or unlawful.
        </p>
        <p>
          We will use appropriate safeguards for transferring your personal data out of the EEA, UK and Switzerland where required and only transfer such personal data under a legally valid transfer mechanism including, where relevant, entering into the EU Standard Contractual Clauses and/or the UK International Data Transfer Agreement / Addendum with the recipient outside the EEA / UK. Please contact us by sending an email to <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a> to obtain a copy.
        </p>

        <h5>13. Changes to This Privacy Policy</h5>
        <p>
          This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at https://bazaa.io. The date the Privacy Policy was last revised is identified at the beginning of this Privacy Policy. You are responsible for periodically visiting our Service and this Privacy Policy to check for any changes.
        </p>

        <h5>14. United States Disclosures</h5>
        <p><strong>a. </strong>
          This section applies if you are a resident of California, Colorado or another U.S. state that has a privacy law similar to the California Consumer Privacy Act (“CCPA”). Over the past 12 months, we have collected the following categories of personal information (including sensitive personal information, denoted by *), as described further above in Section 1: (1) identifiers, (2) commercial information, (3) Internet and similar network activity, (4) geolocation data, (5) information derived from other personal information about you and (6) account access credentials*.
        </p>
        <p><strong>b. </strong>
          The categories of sources from which the personal information is collected are described above in Section 1.
        </p>
        <p><strong>c. </strong>
          The business or commercial purpose for collecting and using personal information, and the third parties we have disclosed such information to over the past 12 months, are described above in Sections 2 and 3. We only use and disclose sensitive personal information for the purposes specified in the CCPA or otherwise in line with your consent. We retain information, including sensitive personal information, as long as it is reasonably necessary for the purposes described in section 8 above.
        </p>
        <p><strong>d. </strong>
          The categories of personal information we shared with third parties over the past 12 months, as described above in Section 3, include: (1) identifiers, (2) commercial information, (3) internet and similar network activity, (4) geolocation data, (5) information derived from other personal information about you and (6) account access credentials*.
        </p>

        <p><strong>e. </strong>
          We do not “sell” or “share” (as those terms are defined under the CCPA) personal information, nor have we done so in the preceding 12 months. Further, we do not have actual knowledge that we sell or share personal information of residents under 16 years of age.
        </p>

        <p>
          Questions and Contact Information: If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact us at <a href="mailto:support@bazaa.io" className='highlight'>support@bazaa.io</a>
        </p>



        {/* <h5>1. Scope and Updates to this Privacy Policy</h5>
        <p>
          <strong>Scope: </strong>This Privacy Policy governs personal information processed by
          Bazaa, encompassing our websites and other online or offline offerings collectively
          referred to as the "Services."
        </p>
        <p>
          <strong>Updates: </strong>We may refine this Privacy Policy from time to time, and if any
          significant changes occur, we'll notify you as required by law. Your continued use of our
          Services after an update means you accept the revised Privacy Policy.
        </p>
        <h5>2. Personal Information We Collect</h5>
        <p>
          The personal information we collect depends on your interaction with our Services and
          legal requirements. We gather information you provide directly, automatically, and from
          other sources.
        </p>
        <h5>A. Personal Information You Provide to Us Directly</h5>
        <ul className="">
          <li>
            <strong>Wallet Connection: </strong>When you connect your Digital Wallet, we may collect
            details like your wallet address, balance, transactions, and interactions with the
            Blockchain.
          </li>
          <li>
            <strong>Transactions: </strong>Information related to purchases you make, including
            details about the transaction, cryptocurrency used, and parties involved.
          </li>
          <li>
            <strong>Your Communications with Us: </strong>If you communicate with us, we may collect
            your email address, phone number, or mailing address.
          </li>
          <li>
            <strong>Surveys, Interactive Features, Sweepstakes, Contests: </strong>Participation in
            surveys or interactive features may involve providing personal information. We may also
            collect data from sweepstakes or contests.
          </li>
          <li>
            <strong>Conferences, Trade Shows, Events: </strong>Information collected during our
            participation in events.
          </li>
          <li>
            <strong>Business Development, Strategic Partnerships:</strong>Personal information
            collected for assessing potential business opportunities.
          </li>
          <li>
            <strong>Job Applications: </strong>When applying for job openings, we collect relevant
            personal information.
          </li>
        </ul>
        <h5>B. Personal Information Collected Automatically</h5>
        <ul className="">
          <li>
            <strong>Automatic Collection: </strong>Information collected automatically, including IP
            address, user settings, cookie identifiers, device information, and browsing behavior.
          </li>
          <li>
            <strong>Technologies (Cookies, Pixel Tags/Web Beacons): </strong> We use Technologies
            for various purposes, such as operation, performance assessment, functionality
            enhancement, and analytics.
          </li>
        </ul>
        <h5>C. Personal Information Collected from Other Sources</h5>
        <p>
          We may obtain personal information from third-party services, including the public
          Blockchain.
        </p>
        <h5>3. How We Use Your Personal Information</h5>
        <p>
          We use your personal information for various purposes, including providing Services,
          administrative functions, and marketing. We may also engage in automated decision-making
          and create de-identified and aggregated information.
        </p>
        <h5>A. Provide Our Services</h5>
        <p>
          We use your information to manage your experience, provide access to functionalities,
          answer support requests, and more.
        </p>
        <h5>B. Administrative Purposes</h5>
        <p>
          For purposes such as direct marketing, security, fraud prevention, improving services, and
          complying with legal obligations.
        </p>
        <h5>C. Marketing and Advertising Our Products and Services</h5>
        <p>
          We may use personal information for tailoring content and advertisements, complying with
          applicable laws.
        </p>
        <h5>D. With Your Consent</h5>
        <p>
          We may use personal information for purposes disclosed to you at the time of collection or
          with your consent.
        </p>
        <h5>E. Other Purposes</h5>
        <p>
          We may use your personal information for other purposes as requested by you or as
          permitted by law.
        </p>
        <h5>4. How We Disclose Your Personal Information</h5>
        <p>
          We may disclose your personal information to third parties for various business purposes,
          including providing Services, protecting interests, or in the event of a significant
          business transaction.
        </p>
        <h5>A. Disclosures to Provide Our Services</h5>
        <p>
          We may share your personal information with service providers, affiliates, other users,
          advertising partners, and through APIs/SDKs.
        </p>
        <h5>B. Disclosures to Protect Us or Others</h5>
        <p>
          We may disclose information to law enforcement, protect rights, property, safety, and
          enforce policies or contracts.
        </p>
        <h5>C. Disclosure in the Event of Merger, Sale, or Other Asset Transfers</h5>
        <p>
          In business transactions, your information may be sold or transferred as permitted by law
          and/or contract.
        </p>
        <h5>5. Your Privacy Choices and Rights</h5>
        <p>
          Your privacy choices and rights are determined by applicable law. You may exercise rights
          such as access, correction, deletion, restriction, objection, and withdrawal of consent.
        </p>
        <h5>6. Security of Your Information</h5>
        <p>
          While we take measures to secure your information, no system is 100% secure. We do not
          accept liability for unauthorized access, use, or loss of personal information.
        </p>
        <h5>7. International Data Transfers</h5>
        <p>
          Your information may be transferred, processed, and stored worldwide, including countries
          with different data protection laws. We use safeguards like EU Standard Contractual
          Clauses for international transfers.
        </p>
        <h5>8. Retention of Personal Information</h5>
        <p>
          We retain personal information as long as necessary for fulfilling the purposes outlined
          in this Privacy Policy, complying with legal obligations, and pursuing legitimate business
          interests.
        </p>
        <h5>9. Supplemental Notice for California Residents</h5>
        <p>
          This notice applies to our processing of personal information under the California
          Consumer Privacy Act (CCPA). Below are the categories of personal information collected by
          Bazaa and the corresponding third parties it may be disclosed to:
        </p>
        <h5>Categories of Personal Information:</h5>
        <ul className="">
          <li>
            <strong>Identifiers: </strong>Digital Wallet and email address.
          </li>
          <li>
            <strong>Internet or Electronic Network Activity: </strong> Browsing and interaction
            history.
          </li>
          <li>
            <strong>Professional or Employment-Related Information: </strong>Job history or
            performance evaluations.
          </li>
          <li>
            <strong>Inferences from Other Personal Information: </strong>Profiles, preferences, and
            behaviors.
          </li>
        </ul>
        <p>
          “Sales” of Personal Information: Bazaa does not sell personal information. Information
          shared with third-party advertising partners is for promoting our services.
        </p>
        <h5>Additional Privacy Rights for California Residents:</h5>
        <ul className="">
          <li>
            <strong>Non-Discrimination: </strong>No discriminatory treatment for exercising CCPA
            rights.
          </li>
          <li>
            <strong>Authorized Agent: </strong>Only you or a legally authorized agent can make
            verifiable requests.
          </li>
          <li>
            <strong>Verification: </strong>Steps will be taken to verify your identity before
            fulfilling requests.
          </li>
        </ul>
        <p>
          <strong>Refer-a-Friend and Incentive Programs: </strong> Participation in referral
          programs is voluntary and supports business growth.
        </p>
        <p>
          <strong>De-Identified Information: </strong>We will not attempt to reidentify
          de-identified information, except to comply with the law.{' '}
        </p>
        <p>
          If you're a California resident and want to exercise your CCPA rights, contact us as
          described in “Contact Us” below.
        </p>
        <h5>10. Supplemental Notice for Nevada Residents</h5>
        <p>
          Nevada residents have the right to opt-out of the sale of certain personal information. To
          exercise this right, contact us at{' '}
          <a href="mailto:support@bazaa.io" class="highlight">
            support@bazaa.io
          </a>{' '}
          with the subject line “Nevada Do Not Sell Request,” providing your name and associated
          email address.
        </p>
        <p>
          Bazaa does not currently sell personal information, as defined in Nevada Revised Statutes
          Chapter 603A.
        </p>
        <p>If you have questions, please contact us as described in "Contact Us" below.</p>
        <h5>11. Children’s Information</h5>
        <p>
          Our Services are not directed at children under 13, and we do not knowingly collect
          personal information from them. Parents or guardians can contact us if they believe a
          child provided information without consent.
        </p>
        <h5>12. Other Provisions</h5>
        <ul className="">
          <li>
            <strong>Third-Party Websites/Applications: </strong>We are not responsible for the
            privacy practices of third-party services linked to our Services.
          </li>
          <li>
            <strong>Supervisory Authority: </strong>If applicable, you have the right to lodge
            complaints with the competent supervisory authority.
          </li>
        </ul>
        <h5>13. Contact Us</h5>
        <p>
          If you have questions or want to exercise your privacy rights, contact us at{' '}
          <a href="mailto:support@bazaa.io" class="highlight">
            support@bazaa.io
          </a>{' '}
          We appreciate your trust and are here to ensure your privacy is respected in our
          innovative marketplace.
        </p> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
