import Common from '../helpers/common.helper';
import { fetchCartAssets } from './cart_action';

export const addProfileUser = (profile) => ({
  type: 'FETCH_PROFILE',
  profile,
});

export const updateLogin = (isLogin) => ({
  type: 'UPDATE_LOGIN',
  is_login: isLogin,
});
export const isProfileUpdated = (is_profile_updated) => ({
  type: 'UPDATE_PROFILE',
  is_profile_updated,
});

export const darkModeUpdate = (isDarkMode) => ({
  type: 'DARK_MODE_UPDATE',
  isDarkMode,
});

export const fetchUserProfile = (vanarAddressLowerCase) => {
  return async (dispatch) => {
    try {
      Common.getSingleDocument('users', vanarAddressLowerCase).then((profile) => {
        dispatch(
          addProfileUser({
            data: profile,
          }),
        );
        dispatch(fetchCartAssets(vanarAddressLowerCase));
        localStorage.setItem('isProfileUpdated', false);
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };
};
