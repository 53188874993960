import React, { useEffect, useState } from 'react';
import { fetchCollectionAnalytics, fetchTrendingCollection } from '../actions/marketplace_action';
import { useDispatch } from 'react-redux';

const useCollectionAnalyticsData = ({ contractAddress }) => {
  const dispatch = useDispatch();

  useEffect(() => {
     dispatch(fetchCollectionAnalytics(contractAddress));
  }, [dispatch, contractAddress]);

  return {};
};

export default useCollectionAnalyticsData;
