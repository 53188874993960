import React, { useContext, useState, useMemo } from 'react';
import { WagmiConfig, createConfig, configureChains } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import * as allChains from 'wagmi/chains';
import ErrorMsgModal from './../modals/ErrorMsgModal';
import { VanarTestChain, VanarVanGuardChain, VanarChainProd } from './VanarChains';

const WalletContext = React.createContext({
  errorMessage: null,
  setErrorMessage: (isVisible) => {},
  setIsDisconnecting: (isConnecting) => {},
  isDisconnecting: false,
  supportedChainID: -1,
});

const WalletProvider = ({ children, credentials }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const walletWagmiConfig = useMemo(() => {
    const chainsList = allChains;

    let supportedChain = null;
    for (const chainKey in chainsList) {
      if (chainsList[chainKey].id === credentials.supportedChainID) {
        supportedChain = chainsList[chainKey];
        break;
      }
    }
    if (supportedChain == null) {
      if (credentials.supportedChainID === 1947) {
        supportedChain = VanarTestChain;
      } else if (credentials.supportedChainID === 78600) {
        supportedChain = VanarVanGuardChain;
      } else {
        supportedChain = VanarChainProd;
      }
    }

    const { chains, publicClient, webSocketPublicClient } = configureChains(
      [supportedChain],
      [publicProvider()],
    );
    const walletWagmiConfigObject = createConfig({
      autoConnect: true,
      connectors: [
        new MetaMaskConnector({
          options: {
            shimDisconnect: true,
            UNSTABLE_shimOnConnectSelectAccount: true,
          },
          chains,
        }),
        new WalletConnectConnector({
          options: {
            projectId: credentials.walletConnectProjectID,
            qrModalOptions: {
              themeVariables: { '--wcm-z-index': '10000' },
            },
          },
          chains,
        }),
        new CoinbaseWalletConnector({
          options: {},
          chains,
        }),
        new InjectedConnector({ chains, options: { shimDisconnect: true } }),
      ],
      publicClient,
      webSocketPublicClient,
    });
    return walletWagmiConfigObject;
  }, [credentials.supportedChainID, credentials.walletConnectProjectID]);

  return (
    <WagmiConfig config={walletWagmiConfig}>
      <WalletContext.Provider
        value={{
          errorMessage,
          setErrorMessage,
          isDisconnecting,
          setIsDisconnecting,
          supportedChainID: credentials.supportedChainID,
        }}
      >
        <>
          {children}
          <ErrorMsgModal />
        </>
      </WalletContext.Provider>
    </WagmiConfig>
  );
};
export default WalletProvider;

export const useWalletProviderContext = () => useContext(WalletContext);
