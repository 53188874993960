import React from 'react'
import '../assets/styles/styles.css'
import { useWalletProviderContext } from '../provider/WalletProvider'

function ErrorMsgModal() {
  const { errorMessage, setErrorMessage } = useWalletProviderContext()

  return (
    <>
      {errorMessage !== null &&
        <div className={`modal-wallet-error modal-wallet-error-open zindex-modal`}>
          <div className="modal-wallet-error-content">
            <span className="modal-wallet-error-close-button" onClick={() => setErrorMessage(null)}>
              &times;
            </span>
            <h5>Alert</h5>
            <p>{errorMessage}</p>
          </div>
        </div>
      }
    </>
  )
}

export default ErrorMsgModal
