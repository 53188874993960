import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = (headerHeight) => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - 90;
        // element.scrollIntoView(offsetPosition);
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
      }
    }
  }, [location,headerHeight]);
};

export default useScrollToHash;
