import React, { memo, useCallback, useContext } from 'react';
import StoreContext from '../../context/StoreContext';
import useDynamicComponent from '../useDynamicComponent';
import LoaderComponent from '../customs/loader/LoaderComponent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
const CreateNFTCollectionRoot = () => {
  const { store } = useContext(StoreContext);

  const handleBtnsClick = useCallback((type) => {}, []);

  const CreateNFTCollection = useDynamicComponent('create_nft/CreateNFTCollection', store);

  if (!CreateNFTCollection) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        <CreateNFTCollection handleBtnsClick={handleBtnsClick} />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default memo(CreateNFTCollectionRoot);
