import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
//
import LoaderComponent from './customs/loader/LoaderComponent';
import { useLocation } from 'react-router-dom';

const Popular = (props) => {
  const { store } = useContext(StoreContext);
  const PopularChildComponent = useDynamicComponent('PopularListing.js', store);
  const location=useLocation();
  const popularityType=location.state;
  if (!PopularChildComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <PopularChildComponent popularityType={popularityType}/>
    </>
  );
};

export default Popular;
