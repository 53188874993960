import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
import LoaderComponent from './customs/loader/LoaderComponent';

const Notification = (props) => {
  const { store } = useContext(StoreContext);
  const NotificationComponent = useDynamicComponent('Notification', store);

  if (!NotificationComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <NotificationComponent />
    </>
  );
};

export default Notification;
