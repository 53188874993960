import {
  TRANSACTION_HISTORY_SUCCESS,
  TRANSACTION_HISTORY_FAILURE,
  TRANSACTION_HISTORY_REQUEST,
  TRANSACTION_HISTORY_RESET,
} from '../actions/transaction_action';

let initialState = {
  is_loading: true,
  lastDocument: null,
  transactions: [],
  error: null,
};

const transactionhistory_reducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_HISTORY_REQUEST:
      return { ...state, is_loading: action.payload.is_loading };

    case TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        is_loading: false,
        transactions: action.payload.transactions,
        lastDocument: action.payload.lastDocument,
      };

    case TRANSACTION_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case TRANSACTION_HISTORY_RESET:
      return { ...initialState };

    default:
      return state;
  }
};

export default transactionhistory_reducer;
