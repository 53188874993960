import React, { useState } from 'react';
import { connect } from 'react-redux';
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL } from '../../../utils/consts';
import { Link } from 'react-router-dom';
import Common from '../../../helpers/common.helper';
//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import LoaderAnimated from '../../../components/customs/loader/LoaderAnimated';
import { Spinner } from 'react-bootstrap';
import ContactSupport from '../../../components/ContactSupport';
const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

const Footer = (props) => {
  const [emailaddress, setEmailaddress] = useState('');
  const [error, setError] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const handleEmailSubmit = async () => {
    let isvalid = validateEmail(emailaddress);
    if (isvalid) {
      try {
        setError(false);
        setIsLoading(true);
        // const token = await executeRecaptcha('NewsLetter');
        // if (!token) {
        //   console.error('reCAPTCHA verification failed. Please try again.');
        //   return;
        // }
        const isEmailExists = await Common.getSingleDocument('newsletter', emailaddress);
        if (isEmailExists?.email) {
          toast('Email already registered');
          setEmailaddress('');
          setIsLoading(false);
        } else {
          await Common.setDocument('newsletter', emailaddress, { email: emailaddress });
          toast('Thank you for joining the Bazaa Community!');
          setEmailaddress('');
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      {/*<a href="#" id="back-to-top"></a>*/}
      <footer id="footer">
        <div className="footer-content">
          <div className="footer">
            <div className="footer-right">
              <div className="footer-right-inner">
                {/* <BazaaLogo className={'footer-logo'} /> */}
                <img
                  alt="logo"
                  className="footer-logo"
                  src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}header-logo.svg`}
                />
                <div className="right-inner-content">
                  <strong>Join Our Community</strong>
                  <div className="input-holder">
                    <input
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmailaddress(e.target.value);
                        setError(false);
                      }}
                      value={emailaddress}
                    />
                    {error && <p className="text-danger">Please enter a valid email</p>}
                    <button type="submit" className="input-holder-btn" onClick={handleEmailSubmit}>
                      {!loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="48"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <rect
                            className="path-color"
                            width="48"
                            height="48"
                            rx="24"
                            fill="#242424"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.0945 16.3123C26.7147 15.9113 26.0818 15.8942 25.6808 16.274C25.2799 16.6538 25.2627 17.2868 25.6425 17.6877L30.6754 23.0008H14.9995C14.4472 23.0008 13.9995 23.4485 13.9995 24.0008C13.9995 24.5531 14.4472 25.0008 14.9995 25.0008H30.6761L25.6425 30.3146C25.2627 30.7156 25.2799 31.3485 25.6808 31.7283C26.0818 32.1081 26.7147 32.091 27.0945 31.69L33.7264 24.6889C34.0918 24.3032 34.0918 23.6991 33.7264 23.3134L27.0945 16.3123Z"
                            fill="#F4F4F4"
                          />
                        </svg>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center h-100">
                          <Spinner animation="border" role="status" size="sm" />
                        </div>
                      )}
                    </button>
                  </div>
                  <ul className="list-none footer-social-icons">
                    {/* <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://www.facebook.com/VirtuaMetaverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.325 11C21.0984 11 19.922 11.4873 19.0546 12.3546C18.1873 13.222 17.7 14.3984 17.7 15.625V18.2H15.225C15.1007 18.2 15 18.3007 15 18.425V21.825C15 21.9493 15.1007 22.05 15.225 22.05H17.7V29.025C17.7 29.1493 17.8007 29.25 17.925 29.25H21.325C21.4493 29.25 21.55 29.1493 21.55 29.025V22.05H24.0469C24.1502 22.05 24.2402 21.9797 24.2652 21.8796L25.1152 18.4796C25.1507 18.3376 25.0433 18.2 24.8969 18.2H21.55V15.625C21.55 15.4195 21.6317 15.2223 21.777 15.077C21.9223 14.9317 22.1195 14.85 22.325 14.85H24.925C25.0493 14.85 25.15 14.7493 25.15 14.625V11.225C25.15 11.1007 25.0493 11 24.925 11H22.325Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li> */}
                    <li>
                      <a
                        style={{ pointerEvents: 'fill' }}
                        className="d-flex align-items-center justify-content-center"
                        href="https://twitter.com/bazaa_io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="41"
                          height="40"
                          viewBox="0 0 41 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.0764 20.512L11.3333 12H16.6743L20.8368 17.261L25.2837 12.0237H28.2253L22.259 19.0587L29.3333 28H24.0082L19.5012 22.3105L14.6894 27.9842H11.7319L18.0764 20.512Z"
                            fill="#1C1C1C"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://www.instagram.com/Virtuametaverse/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        <svg
                          width="41"
                          height="40"
                          viewBox="0 0 41 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.8588 16.9576C19.0638 16.9576 17.6088 18.4126 17.6088 20.2076C17.6088 22.0025 19.0638 23.4576 20.8588 23.4576C22.6537 23.4576 24.1088 22.0025 24.1088 20.2076C24.1088 18.4126 22.6537 16.9576 20.8588 16.9576Z"
                            fill="#242424"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.6283 11.289C19.0764 10.9037 22.6411 10.9037 26.0893 11.289C27.9876 11.5012 29.5188 12.997 29.7417 14.902C30.1539 18.427 30.1539 21.9881 29.7417 25.5131C29.5188 27.4181 27.9876 28.9139 26.0893 29.1261C22.6411 29.5115 19.0764 29.5115 15.6283 29.1261C13.7299 28.9139 12.1987 27.4181 11.9759 25.5131C11.5636 21.9881 11.5636 18.427 11.9759 14.902C12.1987 12.997 13.7299 11.5012 15.6283 11.289ZM25.8588 14.2076C25.3065 14.2076 24.8588 14.6553 24.8588 15.2076C24.8588 15.7599 25.3065 16.2076 25.8588 16.2076C26.411 16.2076 26.8588 15.7599 26.8588 15.2076C26.8588 14.6553 26.411 14.2076 25.8588 14.2076ZM16.1088 20.2076C16.1088 17.5842 18.2354 15.4576 20.8588 15.4576C23.4821 15.4576 25.6088 17.5842 25.6088 20.2076C25.6088 22.8309 23.4821 24.9576 20.8588 24.9576C18.2354 24.9576 16.1088 22.8309 16.1088 20.2076Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://www.youtube.com/VirtuaMetaverse"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="41"
                          height="40"
                          viewBox="0 0 41 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.4366 13.1957C18.7723 12.9348 22.1233 12.9348 25.4589 13.1957L27.6996 13.371C28.9477 13.4687 29.9687 14.4041 30.175 15.6388C30.6912 18.7283 30.6912 21.8821 30.175 24.9716C29.9687 26.2063 28.9477 27.1417 27.6996 27.2394L25.4589 27.4147C22.1233 27.6757 18.7723 27.6757 15.4366 27.4147L13.1959 27.2394C11.9479 27.1417 10.9268 26.2063 10.7205 24.9716C10.2043 21.8821 10.2043 18.7283 10.7205 15.6388C10.9268 14.4041 11.9479 13.4687 13.1959 13.371L15.4366 13.1957ZM18.4478 22.7753V17.8351C18.4478 17.6019 18.7021 17.4578 18.9021 17.5778L23.019 20.048C23.2132 20.1645 23.2132 20.4459 23.019 20.5625L18.9021 23.0326C18.7021 23.1526 18.4478 23.0085 18.4478 22.7753Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://medium.com/bazaa"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="41"
                          height="40"
                          viewBox="0 0 41 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.6283 11.289C19.0764 10.9037 22.6411 10.9037 26.0893 11.289C27.9876 11.5012 29.5188 12.997 29.7417 14.902C30.1539 18.427 30.1539 21.9881 29.7417 25.5131C29.5188 27.4181 27.9876 28.9139 26.0893 29.1261C22.6411 29.5115 19.0764 29.5115 15.6283 29.1261C13.7299 28.9139 12.1987 27.4181 11.9759 25.5131C11.5636 21.9881 11.5636 18.427 11.9759 14.902C12.1987 12.997 13.7299 11.5012 15.6283 11.289ZM25.2694 17.1876L26.1088 16.3847L26.1081 16.2076H23.2064L21.1381 21.3677L18.7853 16.2082H15.7416V16.3847L16.7223 17.5653C16.7697 17.608 16.8064 17.6612 16.8294 17.7208C16.8524 17.7803 16.861 17.8444 16.8545 17.9078V22.549C16.8686 22.6315 16.8631 22.7161 16.8384 22.7961C16.8138 22.8761 16.7706 22.9491 16.7126 23.0094L15.6088 24.3476V24.5234H18.7358V24.3476L17.6327 23.0094C17.5741 22.9492 17.53 22.8764 17.5038 22.7967C17.4775 22.7169 17.4697 22.6322 17.481 22.549V18.5356L20.2258 24.5241H20.5449L22.9022 18.5356V23.3089C22.9022 23.4353 22.9022 23.46 22.8189 23.5434L21.9704 24.3665V24.5423H26.0879V24.3665L25.2694 23.5629C25.234 23.5358 25.2066 23.4997 25.1902 23.4583C25.1738 23.4168 25.169 23.3718 25.1762 23.3278V17.4227C25.169 17.3787 25.1738 17.3337 25.1902 17.2922C25.2066 17.2508 25.234 17.2147 25.2694 17.1876Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://discord.com/invite/rUYtVsh3r8"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="17"
                          viewBox="0 0 23 17"
                          fill="none"
                        >
                          <path
                            d="M19.1361 1.42375C17.712 0.758625 16.1893 0.275242 14.5973 0C14.4018 0.352851 14.1734 0.827443 14.0159 1.20498C12.3236 0.95093 10.6469 0.95093 8.98573 1.20498C8.82828 0.827443 8.59467 0.352851 8.39741 0C6.80374 0.275242 5.27928 0.760401 3.85518 1.42727C0.982756 5.76019 0.20409 9.98549 0.593424 14.1508C2.49856 15.571 4.34487 16.4337 6.16003 16.9983C6.6082 16.3825 7.00791 15.728 7.35224 15.0382C6.69644 14.7895 6.06832 14.4825 5.47482 14.1261C5.63227 14.0097 5.78628 13.8879 5.93508 13.7627C9.55501 15.4528 13.4882 15.4528 17.0648 13.7627C17.2154 13.8879 17.3694 14.0097 17.5251 14.1261C16.9299 14.4842 16.3 14.7912 15.6442 15.04C15.9885 15.728 16.3865 16.3843 16.8364 17C18.6533 16.4355 20.5014 15.5728 22.4065 14.1508C22.8633 9.32214 21.6261 5.13565 19.1361 1.42375ZM7.84541 11.5892C6.75874 11.5892 5.86759 10.5765 5.86759 9.34331C5.86759 8.11012 6.73972 7.0957 7.84541 7.0957C8.95114 7.0957 9.84226 8.10835 9.82323 9.34331C9.82495 10.5765 8.95114 11.5892 7.84541 11.5892ZM15.1545 11.5892C14.0678 11.5892 13.1767 10.5765 13.1767 9.34331C13.1767 8.11012 14.0488 7.0957 15.1545 7.0957C16.2602 7.0957 17.1514 8.10835 17.1323 9.34331C17.1323 10.5765 16.2602 11.5892 15.1545 11.5892Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="d-flex align-items-center justify-content-center"
                        href="https://medium.com/terravirtua"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M26.8261 27.2902L26.8268 27.2885L26.8442 27.245L29.843 12.1259V12.0768C29.843 11.7001 29.7038 11.3713 29.4009 11.174C29.1358 11.0013 28.831 10.989 28.6173 11.0053C28.3913 11.0224 28.1782 11.0788 28.0306 11.1258C27.9547 11.1499 27.8912 11.1732 27.8458 11.1908C27.823 11.1997 27.8045 11.2072 27.791 11.2128L27.7779 11.2183L11.058 17.7772L11.0534 17.7789C11.0444 17.7822 11.0325 17.7866 11.018 17.7923C10.9893 17.8034 10.9501 17.8195 10.9042 17.8403C10.8139 17.8812 10.6891 17.9444 10.5626 18.031C10.3478 18.1781 9.93868 18.5264 10.0078 19.0787C10.065 19.5361 10.3803 19.8266 10.5929 19.977C10.7068 20.0576 10.8158 20.1157 10.8956 20.1538C10.9361 20.173 10.9704 20.1878 10.996 20.1982C11.0089 20.2034 11.0196 20.2076 11.0279 20.2107L11.0385 20.2147L11.0453 20.2172L13.9705 21.202C13.9607 21.3856 13.9789 21.5727 14.0276 21.7573L15.4928 27.3157C15.6666 27.9748 16.2628 28.4339 16.9444 28.4334C17.5553 28.4329 18.0971 28.0633 18.3273 27.5119L20.615 25.0658L24.544 28.0781L24.5998 28.1025C24.9568 28.2582 25.2903 28.3076 25.5952 28.2659C25.8997 28.2243 26.1417 28.0966 26.3235 27.9512C26.5023 27.8081 26.6249 27.6466 26.702 27.525C26.7411 27.4633 26.7704 27.409 26.7906 27.3681C26.8008 27.3476 26.8088 27.3302 26.8148 27.3167L26.8223 27.2993L26.825 27.2928L26.8261 27.2902ZM15.478 21.375C15.4446 21.2486 15.4969 21.1153 15.6072 21.0452L25.5292 14.7443C25.5292 14.7443 26.1128 14.39 26.092 14.7443C26.092 14.7443 26.1961 14.8069 25.8834 15.0986C25.5868 15.3757 18.8117 21.9167 18.1262 22.5786C18.0866 22.6168 18.063 22.6607 18.049 22.7139L16.9432 26.9334L15.478 21.375Z"
                            fill="#242424"
                          />
                        </svg>
                      </a>
                    </li> */}
                  </ul>
                </div>
                {/* <div className="text-center user-guidelines">
                  <img
                    className="light-v"
                    src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}powered-vinar.svg`}
                    alt="light"
                  />
                  <img
                    className="dark-v"
                    src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}powered-vanar-dark.svg`}
                    alt="dark"
                  />
                </div> */}
                {/* <div>
                  <span className="title d-block text-center">
                    Vanar is a registered trademark of Vanar Limited. All logos are registered
                    trademarks of their respective owners. All content of this document, unless
                    otherwise credited, are copyright @ {new Date().getUTCFullYear()} Vanar Limited.
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="user-guidelines-footer">
          <div className="user-guidelines gap-2 container">
            <div className='suupport_help'>
              <span className='fw-600'>Need help?</span> <button class="btn-main support_btn" onClick={()=>setIsOpen(!isOpen)}>Contact Support</button>
            </div>
            <ul className="list-none d-flex align-items-center">
              <li>
                <Link to="/user-guidelines" rel="noreferrer">
                  User Guidelines
                </Link>
              </li>
              <li>
                <Link to="/privacy" rel="noreferrer">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" rel="noreferrer">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <ContactSupport isOpen={isOpen} onHide={()=>setIsOpen(!isOpen)}/>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Footer);
