import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { closeIcon, REACT_APP_S3_CLOUDFLARE_ASSETS_URL } from '../utils/consts';

const ContactSupport = ({ onHide, isOpen }) => {
  const handleClose = () => {
    onHide();
  };

  return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="w-600"
        contentClassName="border-box top connect-wallet-modal"
        show={isOpen}
        centered
      >
        <div className="inner-content text-center">
          <div className="modal-title text-center">
            <img
              width={80}
              height={80}
              src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}mob-beta.svg`}
              alt="bazaa-connect"
            />
            <h3 className="title mb-0">Contact Support</h3>
            <p className="mb-3">Reach out to our support team for solutions.</p>
            <ul className="list-none footer-social-icons contact_support">
              <li>
                <a
                  style={{ pointerEvents: 'fill' }}
                  className="d-flex align-items-center justify-content-center"
                  href="https://twitter.com/bazaa_io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.0764 20.512L11.3333 12H16.6743L20.8368 17.261L25.2837 12.0237H28.2253L22.259 19.0587L29.3333 28H24.0082L19.5012 22.3105L14.6894 27.9842H11.7319L18.0764 20.512Z"
                      fill="#1C1C1C"
                    />
                  </svg>
                </a>
              </li>
              {/* <li>
                <a
                  className="d-flex align-items-center justify-content-center"
                  href="https://medium.com/bazaa"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.6283 11.289C19.0764 10.9037 22.6411 10.9037 26.0893 11.289C27.9876 11.5012 29.5188 12.997 29.7417 14.902C30.1539 18.427 30.1539 21.9881 29.7417 25.5131C29.5188 27.4181 27.9876 28.9139 26.0893 29.1261C22.6411 29.5115 19.0764 29.5115 15.6283 29.1261C13.7299 28.9139 12.1987 27.4181 11.9759 25.5131C11.5636 21.9881 11.5636 18.427 11.9759 14.902C12.1987 12.997 13.7299 11.5012 15.6283 11.289ZM25.2694 17.1876L26.1088 16.3847L26.1081 16.2076H23.2064L21.1381 21.3677L18.7853 16.2082H15.7416V16.3847L16.7223 17.5653C16.7697 17.608 16.8064 17.6612 16.8294 17.7208C16.8524 17.7803 16.861 17.8444 16.8545 17.9078V22.549C16.8686 22.6315 16.8631 22.7161 16.8384 22.7961C16.8138 22.8761 16.7706 22.9491 16.7126 23.0094L15.6088 24.3476V24.5234H18.7358V24.3476L17.6327 23.0094C17.5741 22.9492 17.53 22.8764 17.5038 22.7967C17.4775 22.7169 17.4697 22.6322 17.481 22.549V18.5356L20.2258 24.5241H20.5449L22.9022 18.5356V23.3089C22.9022 23.4353 22.9022 23.46 22.8189 23.5434L21.9704 24.3665V24.5423H26.0879V24.3665L25.2694 23.5629C25.234 23.5358 25.2066 23.4997 25.1902 23.4583C25.1738 23.4168 25.169 23.3718 25.1762 23.3278V17.4227C25.169 17.3787 25.1738 17.3337 25.1902 17.2922C25.2066 17.2508 25.234 17.2147 25.2694 17.1876Z"
                      fill="#242424"
                    />
                  </svg>
                </a>
              </li> */}
              <li>
                <a
                  className="d-flex align-items-center justify-content-center"
                  href="https://discord.com/invite/rUYtVsh3r8"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="17"
                    viewBox="0 0 23 17"
                    fill="none"
                  >
                    <path
                      d="M19.1361 1.42375C17.712 0.758625 16.1893 0.275242 14.5973 0C14.4018 0.352851 14.1734 0.827443 14.0159 1.20498C12.3236 0.95093 10.6469 0.95093 8.98573 1.20498C8.82828 0.827443 8.59467 0.352851 8.39741 0C6.80374 0.275242 5.27928 0.760401 3.85518 1.42727C0.982756 5.76019 0.20409 9.98549 0.593424 14.1508C2.49856 15.571 4.34487 16.4337 6.16003 16.9983C6.6082 16.3825 7.00791 15.728 7.35224 15.0382C6.69644 14.7895 6.06832 14.4825 5.47482 14.1261C5.63227 14.0097 5.78628 13.8879 5.93508 13.7627C9.55501 15.4528 13.4882 15.4528 17.0648 13.7627C17.2154 13.8879 17.3694 14.0097 17.5251 14.1261C16.9299 14.4842 16.3 14.7912 15.6442 15.04C15.9885 15.728 16.3865 16.3843 16.8364 17C18.6533 16.4355 20.5014 15.5728 22.4065 14.1508C22.8633 9.32214 21.6261 5.13565 19.1361 1.42375ZM7.84541 11.5892C6.75874 11.5892 5.86759 10.5765 5.86759 9.34331C5.86759 8.11012 6.73972 7.0957 7.84541 7.0957C8.95114 7.0957 9.84226 8.10835 9.82323 9.34331C9.82495 10.5765 8.95114 11.5892 7.84541 11.5892ZM15.1545 11.5892C14.0678 11.5892 13.1767 10.5765 13.1767 9.34331C13.1767 8.11012 14.0488 7.0957 15.1545 7.0957C16.2602 7.0957 17.1514 8.10835 17.1323 9.34331C17.1323 10.5765 16.2602 11.5892 15.1545 11.5892Z"
                      fill="#242424"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <span className="modals-close-icon" onClick={handleClose}>
            {closeIcon}
          </span>
          {/* <img className="modals-close-icon" src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}close-circle.svg`} alt="crossIcon" onClick={handleClose} /> */}
        </div>
      </Modal>
  );
};

export default memo(ContactSupport);
