/* eslint-disable react-hooks/exhaustive-deps */
// useDynamicComponent.js
import {  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import StoreContext from '../context/StoreContext';

const useDynamicComponent = (componentName) => {
  const [Component, setComponent] = useState(null);
  const navigate = useNavigate()
  // const { theme_id } = useContext(StoreContext);

  const theme_id=process.env.REACT_APP_THEME_ID

  useEffect(() => {
    const theme = theme_id ?? 'main';
    const loadComponent = async () => {
      try {
        // Dynamically import the component based on the given componentName
        const module = await import(`../themes/${theme}/attributes.js`);
        const componentModule = await import(`../themes/${theme}/${componentName}`);
        if (module?.particlesJS && componentName === 'Home') {
          window.particlesJS('particles-js', {
            particles: {
              number: {
                value: 50,
                density: { enable: true, value_area: 800 },
              },
              color: { value: '#8082F0' },
              shape: {
                type: 'circle',
                stroke: { width: 0, color: '#000000' },
                polygon: { nb_sides: 5 },
                image: { src: 'img/github.svg', width: 100, height: 100 },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 5,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: '#ffffff',
                opacity: 0.3,
                width: 1,
              },
              move: {
                enable: true,
                speed: 6,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: { enable: true, mode: 'repulse' },
                onclick: { enable: true, mode: 'push' },
                resize: true,
              },
              modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: { distance: 200 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 },
              },
            },
            retina_detect: true,
            config_demo: {
              hide_card: false,
              background_color: '#b61924',
              background_image: '',
              background_position: '50% 50%',
              background_repeat: 'no-repeat',
              background_size: 'cover',
            },
          });
          setTimeout(function () {
            window.loadOnReady?.();

            setComponent(componentModule.default);
          }, 1500);
        } else {
          setComponent(componentModule.default);
        }
      } catch (error) {
        console.error(`Error loading ${componentName} component:`, error);
        navigate('/')
      }
    };

    loadComponent();
  }, [componentName, theme_id]);

  return Component;
};

export default useDynamicComponent;
