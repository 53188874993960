import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
  startAfter,
  limit,
  orderBy,
  deleteDoc,
  writeBatch,
  getCountFromServer,
  or,
  and,
  updateDoc,
} from 'firebase/firestore';
import { app } from '../firebase';
import { CRYPTO_COMPARE_API, DISCORD_CLIENT_ID, GCP_API_URL, LEDGER_API_URL } from '../app/index';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { store } from '../store/store-config';
import Service from '../app/service';
import { toast } from 'react-toastify';
import { createThumbnailFile } from './nftHelper';
const db = getFirestore(app);
const logEventsApi = process.env.REACT_APP_VANAR_ANALYTICS_API;

export default class Common {
  static async getFirebaseAccessToken() {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const tokenResult = await user.getIdTokenResult();
            const idToken = tokenResult.token;
            resolve(idToken);
          } catch (error) {
            reject(error);
          }
        } else {
          reject(new Error('User not authenticated.'));
        }
      });
    });
  }

  static async getVcoins() {
    let token = await this.getFirebaseAccessToken();

    return axios
      .get(LEDGER_API_URL + 'marketplace/vcoins', {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        return response.data.payload.vcoins;
      })
      .catch((error) => {
        return 0;
      });
  }

  static async getSingleDocument(collectionPath, docId) {
    const docRef = doc(db, collectionPath, docId);
    const docSnap = await getDoc(docRef);
    let docData = null;
    if (docSnap.exists()) {
      docData = docSnap.data();
    } else {
      // console.log("No such document!")
    }

    return docData;
  }
  static async isDocumentExist(collectionPath, docId) {
    try {
      const docRef = doc(db, collectionPath, docId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists();
    } catch (error) {
      return false;
    }
  }
  static async getDefaultTheme(collectionPath) {
    const q = query(collection(db, collectionPath), where('is_default', '==', true));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
      let docData = doc.data();
      docData['id'] = doc.id;
      docs.push(docData);
    });

    return docs[0];
  }

  static async getSingleDocumentFromMultiple(collectionPath, whereColumn, filterValue) {
    const q = query(collection(db, collectionPath), where(whereColumn, '==', filterValue));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
      let docData = doc.data();
      docData['id'] = doc.id;
      docs.push(docData);
    });
    return docs[0];
  }

  static async setDocument(collectionPath, docId, data) {
    await setDoc(doc(db, collectionPath, docId), data, { merge: true });
  }

  static async replaceDocument(collectionPath, docId, data) {
    await setDoc(doc(db, collectionPath, docId), data);
  }

  static async updateDocument(collectionPath, docId, data) {
    await updateDoc(doc(db, collectionPath, docId), data);
  }
  static async removeDocument(collectionPath, docId) {
    return deleteDoc(doc(db, collectionPath, docId));
  }

  static async removeBatchDocs(collectionPath, docIds) {
    const batch = writeBatch(db);
    docIds.forEach((docId) => {
      const docRef = doc(db, collectionPath, docId);
      batch.delete(docRef);
    });

    await batch.commit();
  }

  static async addAttribute(collectionPath, docId, attribute_name, value) {
    const data = {
      [`${attribute_name}`]: value,
    };
    await setDoc(doc(db, collectionPath, docId), data, { merge: true });
  }
  static async bulkUpdateDocuments(collectionPath, documents) {
    const batch = writeBatch(db);
    const collectionToUpdate = collection(db, collectionPath);
    documents.forEach((docData) => {
      const docRef = doc(collectionToUpdate, docData.id);
      batch.set(docRef, docData, { merge: true });
    });

    await batch.commit();
  }
  static async getTotalOwnedNfts(owner_id, STORE_ID) {
    const coll = collection(db, `/stores/${STORE_ID}/nfts`);
    const q = query(coll, where('owner_id', '==', owner_id));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  static async getAssetDetail(collectionPath, code) {
    const q = query(collection(db, collectionPath), where('code', '==', code));
    const querySnapshot = await getDocs(q);
    let docs = [];
    querySnapshot.forEach((doc) => {
      let docData = doc.data();
      docData['id'] = doc.id;
      docs.push(docData);
    });

    return docs[0];
  }
  static async getDocuments(collectionPath) {
    let q = query(collection(db, collectionPath));
    const querySnapshot = await getDocs(q);

    let docs = [];
    querySnapshot.forEach((doc) => {
      let docData = doc.data();

      docData['id'] = doc.id;
      docs.push(docData);
    });

    return docs;
  }
  static isEmpty (item) {
    if (item === '' || item == null || (item instanceof FileList && item.length === 0)) {
      return true;
    }
    return false;
  };

  static async fetchTraits(collectionAddress) {
    let idToken = await this.getFirebaseAccessToken();
    const data = {
      collectionAddress,
    };
    try {
      const response = await Service.postCall(GCP_API_URL + 'nftTraits', data, {
        headers: {
          Authorization: idToken,
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.traits;
    } catch (error) {
      console.error(error)
    }


  }
  static async organizingDocuments(data, order, orderColumn, lastDocument, pageSize) {
    if (orderColumn) {
      data = query(data, orderBy(orderColumn, order));
    }

    if (lastDocument) {
      data = query(data, startAfter(lastDocument));
    }

    data = query(data, limit(pageSize));

    const querySnapshot = await getDocs(data);

    let docs = [];
    let lastDoc;
    querySnapshot.forEach((doc) => {
      let docData = doc.data();
      docData['id'] = doc.id;
      docs.push(docData);
      lastDoc = doc;
    });
    return { docs, lastDoc };
  }

  static async getAllDocumentsWithOrCondition(
    collectionPath,
    filters,
    orderColumn = 'id',
    order = 'asc',
    pageSize,
    lastDocument = null,
  ) {
    let q = query(collection(db, collectionPath));
    q = query(
      q,
      and(
        or(
          where(filters[0].key, filters[0].op, filters[0].value),
          where(filters[1].key, filters[1].op, filters[1].value),
        ),
        where(filters[2].key, filters[2].op, filters[2].value),
      ),
    );
    const organized_documents = await Common.organizingDocuments(
      q,
      order,
      orderColumn,
      lastDocument,
      pageSize,
    );
    let { docs, lastDoc } = organized_documents;
    return { docs, lastDoc };
  }
  static async getAllDocuments(
    collectionPath,
    filters = {},
    orderColumn = 'id',
    order = 'asc',
    pageSize = 24,
    lastDocument = null,
  ) {
    let q = query(collection(db, collectionPath));

    filters?.forEach((condition) => {
      q = query(q, where(condition.key, condition.op, condition.value));
    });

    let organized_documents = await Common.organizingDocuments(
      q,
      order,
      orderColumn,
      lastDocument,
      pageSize,
    );
    let { docs, lastDoc } = organized_documents;
    return { docs, lastDoc };
  }
  static async getCollectionItemCount(path) {
    try {
      const collectionRef = collection(db, path);

      const snapshot = await getCountFromServer(collectionRef);
      const itemCount = snapshot.data().count;
      return itemCount;
    } catch (error) {
      return 0;
    }
  }
  static strLimit(str, length = 30) {
    return str?.length > length ? str.substring(0, length - 3) + '...' : str;
  }

  static isAlphaNumeric(str) {
    var code, i, len;

    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        !(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return false;
      }
    }
    return true;
  }

  static async getEthAmount(amount) {
    const USD = await axios.get(CRYPTO_COMPARE_API).then((response) => {
      return response.data.USD;
    });
    const ETH = parseFloat(1 / USD);
    return (ETH * amount).toFixed(4);
  }

  static parseAmount(amount) {
    return Math.floor(Number(amount));
    // return parseFloat(amount).toFixed(2)
  }

  static getCreatedDate(timestamp) {
    const date = new Date(timestamp);
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    return `${month.substring(0, 3)} ${year}`;
  }

  static delay(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }
  static getTruncatedAddress(str) {
    if (str !== '') {
      return str?.slice(0, 5) + str?.slice(-8).replace(/.(?=...)/g, '*');
    }
    return '';
  }
  static convertObjectToArray(obj, prop) {
    const array = [];

    for (const key in obj) {
      array.push({ key: key, op: '==', value: obj[key] });
    }

    return array;
  }
  static setOperatorInArray(filters, key, op) {
    filters.forEach((condition) => {
      if (condition.key === key) {
        condition.op = op;
      }
    });
  }

  static valueExistsForKey(array = [], key, value) {
    return array?.some((obj) => {
      if (obj[key] && obj[key].includes('_')) {
        const indexOfUnderscore = obj[key].indexOf('_');
        var secondPart = obj[key].slice(indexOfUnderscore + 1, obj[key].length);
        return secondPart === value;
      }
      return false;
    });
  }
  static async logEvent(walletAddress, eventType, source) {
    const data = {
      wallet_address: walletAddress,
      event_type: eventType,
      source_from: source,
    };
    try {
      await Service.postCall(logEventsApi, data, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VANAR_ANALYTICS_API_KEY}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log('error ===> ', error);
    }
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static viewSocialProfile(service, id, username) {
    switch (service) {
      case 'discord':
        return `https://discord.com/users/${id}`;

        break;
      case 'twitter':
        return `https://twitter.com/${username}`;

        break;

      default:
        break;
    }
  }
  static async connectSocials(service, vanarAddressLowerCase, redirect_uri, collectionData) {
    let idToken = await this.getFirebaseAccessToken();
    const updateUri = encodeURIComponent(redirect_uri);
    const jsonString = JSON.stringify(collectionData);

    const base64String = btoa(jsonString);

    switch (service) {
      case 'discord':
        localStorage?.setItem('service', 'discord');
        window.open(
          `https://discord.com/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&response_type=code&state=${base64String}&redirect_uri=${updateUri}&scope=identify`,
          '_self',
        );
        break;
      case 'twitter':
        localStorage?.setItem('service', 'twitter');
        const data = {
          service: 'twitter',
          code: '',
          scope: ['tweet.read', 'users.read'],
          redirect_uri: redirect_uri,
          user: vanarAddressLowerCase,
          state: base64String,
        };
        const url = await Service.postCall(GCP_API_URL + 'socialLogins', data, {
          headers: {
            Authorization: idToken,
            'Content-Type': 'application/json',
          },
        });
        window.open(url.data, '_self');

        break;

      default:
        break;
    }
  }
  static async verify(code, service, vanarAddressLowerCase, socialLogin, redirect_uri) {
    let idToken = await this.getFirebaseAccessToken();
    const urlObject = new URL(redirect_uri);
    urlObject.search = '';
    const newUrl = urlObject.toString();

    const data = {
      service: service,
      code: code,
      scope: '',
      redirect_uri: newUrl,
      user: service === 'twitter' ? vanarAddressLowerCase : '',
    };

    try {
      const url = await Service.postCall(GCP_API_URL + 'socialLogins', data, {
        headers: {
          Authorization: idToken,
          'Content-Type': 'application/json',
        },
      });
      const index = socialLogin.findIndex((item) => item.service === service);
      let updatedSocialLogin;
      if (index !== -1) {
        updatedSocialLogin = [...socialLogin];
        updatedSocialLogin[index] = {
          ...updatedSocialLogin[index],
          username: url.data.username,
          id: url.data.id,
          name: url.data.name ?? url.data.global_name,
        };

        localStorage.setItem('socials', JSON.stringify(updatedSocialLogin));
      }
      toast(`${this.capitalizeFirstLetter(service)} successfully connected`);
      window.history.replaceState(null, null, window.location.pathname);
      return updatedSocialLogin;
    } catch (error) {
      console.log(error);
      toast('Something went wrong, Please try later');
      window.history.replaceState(null, null, window.location.pathname);
      return socialLogin;
    }
  }

  static async disconnectSocial(service, socialLogin, setSocialLogin) {
    const index = socialLogin.findIndex((item) => item.service === service);
    let updatedSocialLogin;
    if (index !== -1) {
      updatedSocialLogin = [...socialLogin];
      updatedSocialLogin[index] = {
        ...updatedSocialLogin[index],
        username: '',
        id: null,
        name: '',
      };

      localStorage.setItem('socials', JSON.stringify(updatedSocialLogin));
      setSocialLogin(updatedSocialLogin);
    }
    toast(`${this.capitalizeFirstLetter(service)} successfully disconnected`);
    return updatedSocialLogin;
  }

  static convertDataURLtoFile(dataURL, fileName, fileType) {
    try {
      if (dataURL) {
        const byteString = atob(dataURL?.split(',')[1]);
        const ab = new ArrayBuffer(byteString?.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString?.length; i++) {
          ia[i] = byteString?.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: fileType });

        const imageFile = new File([blob], fileName, { type: fileType });

        return imageFile;
      }
    } catch (error) {}
  }

  static getRandomNumber(min, max) {
    const randomDecimal = Math.random();
    const randomNumber = Math.floor(randomDecimal * (max - min + 1)) + min;

    return randomNumber;
  }
  static async getRandomUser() {
    const users = await this.getSingleDocument('/usernames', 'usernames');
    const randomUsers = users.usernames;
    let randomIndex = this.getRandomNumber(0, randomUsers?.length);
    let randomUserName = randomUsers[randomIndex];
    return randomUserName + new Date().getTime();
  }

  static async compressImage(customWidth, customHeight, file) {
    const thumbBufferFile = await createThumbnailFile(
      file,
      'jpg',
      customWidth,
      customHeight,
      'thumb_123',
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event.target.result;
        resolve(result); // Resolve with the Data URL
      };
      reader.onerror = (error) => {
        reject(error); // Reject if FileReader encounters an error
      };
      reader.readAsDataURL(thumbBufferFile);
    });
  }

  static getImageResolution(file, height, width) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();

        img.onload = function () {
          const resolution = {
            width: img.width,
            height: img.height,
          };

          // Check if resolution is greater than HD (1920x1080)
          const exceedsHDResolution = resolution.width > width || resolution.height > height;

          resolve(exceedsHDResolution);
        };

        img.onerror = function () {
          reject(new Error('Failed to load image'));
        };

        img.src = event.target.result;
      };

      reader.onerror = function () {
        reject(new Error('Failed to read file'));
      };

      reader.readAsDataURL(file);
    });
  }
}

export const alreadyInCart = (value) => {
  const cartAssets = store.getState()?.cart_assets || [];
  if (cartAssets?.length > 0) {
    return cartAssets.some((ele) => ele?.id === value);
  } else {
    return false;
  }
};
export function correctAddress(addr) {
  return addr.startsWith('0x') ? addr : '0x' + addr;
}
