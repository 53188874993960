import React from 'react';
import './loader.css';
import { REACT_APP_S3_CLOUDFLARE_ASSETS_URL } from '../../../utils/consts';
// import { appLogoSmall } from '../../../assets/images';

function LoaderComponent(props) {
  return (
    <div className="rotating-image-container" style={props?.style ?? {}}>
      <img
        style={{ width: '150px', height: '150px' }}
        src={`${REACT_APP_S3_CLOUDFLARE_ASSETS_URL}bazaa-loading.gif`}
        alt=""
        className="rotating-image"
      />
    </div>
  );
}

export default LoaderComponent;
