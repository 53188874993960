import React, { memo} from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ProfileSettings from '../../themes/main/ProfileSettings';
const ProfileSettingRoot = () => {

  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
        <ProfileSettings />
      </GoogleReCaptchaProvider>
    </>
  );
};

export default memo(ProfileSettingRoot);
