import React from 'react';

const TermsAndCondition = (props) => {
  return (
    <div className="content-page">
      <div className="container">
        <h1 className="main-title">Terms & Conditions</h1>
        <h5>Introduction</h5>
        <p>
          Welcome to Bazaa Marketplace ("Bazaa Marketplace", the "Company", "we", "us", or "our"). The following outlines the Terms and Conditions governing the use of the Bazaa Marketplace website and any of our other associated products or services. By connecting a wallet to our website or otherwise using and accessing our services, you, as the user of the service ("you", "your", or "user"), agree to be bound by these Terms and Conditions.PLEASE CAREFULLY READ THROUGH THE ENTIRETY OF THESE TERMS AND CONDITIONS AS THEY APPLY TO AND AFFECT YOUR LEGAL RIGHTS. BY USING OUR SERVICES, YOU FULLY AGREE THAT YOU ARE AWARE OF THE RISKS ASSOCIATED WITH PARTAKING IN TRANSACTIONS INVOLVING DIGITAL ASSETS, IN particular, THOSE TRANSACTIONS DONE THROUGH MEANS OF DIGITAL CURRENCIES AND YOU AGREE THAT WE SHALL NOT BE HELD LIABLE FOR ANY ADVERSE OUTCOMES RESULTING FROM YOUR PARTICIPATION IN SUCH RISK. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT ACCESS OR USE THE SERVICE.
        </p>
        <p>
          Bazaa Marketplace is not a wallet provider, broker, financial institution, money services business, creditor, or currency exchange and we do not provide associated services. Bazaa Marketplace is a service provider that helps users to discover and interact with NFT (Non Fungible Tokens) using its marketplace, as well as create and develop NFTs on the VANAR blockchain while using its minting and creation tools. We provide a service that aids in the facilitation of purchases, transfers, and sales of NFTs. We do not obtain custody or control over the NFTs that our users are interacting with. Further, Bazaa Marketplace is not a wallet provider, exchange, broker, dealer, financial institution, payments processor, money services business, or creditor. In order to interact with the NFTs accessible through our service, you must use a third-party wallet and/or service which allows you to engage in blockchain transactions. Please ensure you have fully acknowledged and agreed to the separate, individual terms associated with the use of any third-party wallet and/or service that you are using to interact with our service.
        </p>
        <p>
          Bazaa Marketplace is not a party to any agreement between any users. You bear full responsibility for verifying the identity, legitimacy, and authenticity of NFTs that you purchase from third-party sellers using the Service and we are not liable to provide authenticity about the identity, legitimacy, functionality of users or NFTs (and any content associated with such NFTs) visible on the Service.
        </p>
        <p>
          Bazaa Marketplace reserves the right to change or modify these Terms at any time and in our sole discretion. If we make material changes to these Terms, we will use reasonable efforts to provide notice of such changes, such as by providing notice through the Service or updating the “Last Updated” date at the beginning of these Terms. By continuing to access or use the Service, you confirm your acceptance of the revised Terms and all of the terms incorporated therein by reference effective as of the date these Terms are updated. It is your sole responsibility to review the Terms from time to time to view such changes and to ensure that you understand the terms and conditions that apply when you access or use the Service.
        </p>

        <h5>Interpretation and Definitions</h5>
        <h5>Interpretation</h5>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <h5>Definitions</h5>
        <p>For the purposes of these Terms and Conditions:</p>
        <p>
          Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
          Profile means a unique profile created for You to access our Service or parts of our Service.
          Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Bazaa
        </p>
        <p>
          NFT (Non-Fungible Token) means a unique, indivisible digital asset stored on a blockchain, often representing ownership or proof of authenticity for digital or physical items
        </p>
        <p>
          Content refers to content such as text, files, images, videos, NFTs or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.
        </p>
        <p>
          Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
        </p>
        <p>
          Feedback means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.
        </p>
        <p>Goods refer to the items offered for sale on the Service.</p>
        <p>
          Promotions refer to contests, sweepstakes or other promotions offered through the Service.
          Service refers to services provided by Bazaa, including but not limited to the Bazaa Marketplace website
        </p>
        <p>
          Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
          Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
        </p>
        <p>
          {/* Website refers to Bazaa Marketplace, accessible from <a href="https://beta.bazaa.io/" target="_blank">https://beta.bazaa.io/</a> */}
          Website refers to Bazaa Marketplace, accessible from <a href="https://bazaa.io/" target="_blank">https://bazaa.io/</a>
          <br />
          You mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
        </p>
        <h5>Acknowledgment</h5>
        <p>
          These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
        </p>
        <p>
          Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
        </p>
        <p>
          You represent that you are over the age of 18. If you are at least 13 years old but under 18 years old, you may only use Bazaa Marketplace through a parent or guardian’s account and with their approval and oversight. That account holder is responsible for your actions using the account. It is prohibited to use our Service if you are under 13 years old.
        </p>
        <p>
          Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
        </p>

        <h5>Purchase of Goods</h5>
        <p>
          By placing an Order for Goods through the Service, You warrant that You are legally capable of entering into binding contracts.
        </p>
        <p>
          You are solely responsible for identifying and verifying the legitimacy and authenticity of Content that you purchase using the Service. We make no claims about the identity and legitimacy of Content displayed and/or available for purchase through our Service. Please conduct your own research prior to making a purchase decision.
        </p>
        <p>
          Purchasing goods/content through the Service does not guarantee or provide in any way, any claim to copyright, trademark, or intellectual rights of that Content. Licensing and transfer of such any license and/or rights of the Content available for purchase through the Service is not provided by the Service. We do not assert or guarantee the credibility, validity, operation, or genuineness of the users or NFTs (or any materials accompanying the NFTs) that are viewable on the Service. It is the responsibility of the person that is creating, displaying, and/or selling the content to ensure that they have all rights, licenses and/or authority to display and sell such content, and to grant those rights and licenses to others.
        </p>

        <h5>Availability, Errors and Inaccuracies</h5>
        <p>
          We are constantly updating Our offerings of Goods on the Service. The Goods available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our Goods on the Service and in Our advertising on other websites.
        </p>
        <p>
          We do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
        </p>

        <h5>Promotions</h5>
        <p>
          Any Promotions made available through the Service may be governed by rules that are separate from these Terms.
        </p>
        <p>
          If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.
        </p>

        <h5>User Profiles</h5>
        <p>
          When You connect a wallet to the Service a Profile associated with that wallet is created for You based on your linked blockchain address and the NFTs it holds. By connecting and using the wallet with our Service, you agree that you are using that wallet following the terms and conditions of the applicable provider of the wallet. Wallets are not owned, operated, or maintained by the Company, nor does the Company have custody or control over the contents of your wallet. The Company accepts no responsibility for, or liability to you, in connection with your use of a wallet and makes no representations or warranties regarding how the Service will operate with any specific wallet.
        </p>
        <p>
          You are solely responsible to ensure the security of your wallet and refrain from disclosing your wallet login details or seed phrase to anyone. You are responsible for safeguarding the seed phrase associated with your wallet and the signing password that You use to access the Service and for any activities or actions under Your password. In the event of a problem relating to your wallet, kindly get in touch with your wallet provider. We hold no responsibility for any actions or oversights on your part that may stem from or result in your wallet being compromised. You are solely responsible for any information associated with the user profile based on the wallet you connected. If you are misusing our Services or misrepresenting yourself in a way that may cause harm to us or our users, we reserve the right to terminate your Profile and suspend your wallet from accessing our service.
        </p>
        <p>
          Bazaa Marketplace reserves the right to ask for additional information and documents in certain circumstances, such as at the request of any government authority, as any applicable law or regulation dictates, or to investigate a potential violation of these Terms. In such cases, Bazaa Marketplace, in its sole discretion, may disable your Account and block your ability to access the Service until such additional information and documents are processed by Bazaa Marketplace. If you do not provide complete and accurate information in response to such a request, Bazaa Marketplace may refuse to restore your access to the Service.
        </p>
        <p>
          The Service is not available for users under the age of 13. By accessing or using the Service, you confirm that you are at least 13 years old. If you are between the ages of 13 and 18, you may only use Bazaa Marketplace through a parent or guardian’s account and with their approval and oversight. The account holder is responsible for your actions using the account. It is prohibited to use our Service if you are under 13 years old.
        </p>

        <h5>Content</h5>
        <h5>Your Right to Post Content</h5>
        <p>
          Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.
        </p>
        <p>
          By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.
        </p>
        <p>
          You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
        </p>

        <h5>Content Restrictions</h5>
        <p>
          The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.
        </p>
        <p>
          You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:
        </p>
        <p>Unlawful or promoting unlawful activity.</p>
        <p>
          Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
          Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.
          Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.
        </p>
        <p>Content that may involve child sexual exploitation.</p>
        <p>
          Create or display NFTs or other items that promote suicide or self-harm, incite hate or violence against others, or dox another individual.
        </p>
        <p>
          Use the Service to create, sell, or buy NFTs or other items that give owners rights to participate in an ICO or any securities offering, or that are redeemable for securities, commodities, or other financial instruments.
        </p>
        <p>
          Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.
        </p>
        <p>
          Impersonating any person or entity including the Company and its employees or representatives.
        </p>
        <p>Violating the privacy of any third person.</p>
        <p>
          Engage in behaviors that have the intention or the effect of artificially causing an item or collection to appear at the top of search results, or artificially increasing view counts, favorites, or other metrics that Bazaa Marketplace might use to sort search results
          Use the Service for or in connection with money laundering, terrorist financing, or other illicit financial activity, or in any way in connection with the violation of any law or regulation that applies to you or to Bazaa Marketplace.
        </p>
        <p>
          Use the Service to engage in price manipulation, fraud, or other deceptive, misleading, or manipulative activity.
        </p>
        <p>False information and features.</p>
        <p>
          The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with these Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.
        </p>

        <h5>Content Backups</h5>
        <p>
          Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.
        </p>
        <p>
          Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.
        </p>
        <p>
          The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.
        </p>
        <p>
          You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.
        </p>

        <h5>Copyright Policy</h5>
        <h5>Intellectual Property Infringement</h5>
        <p>
          You are solely responsible for the content you create, post, sell, buy, promote, etc, through our Service. Use of our Service is a statement that you have been granted all necessary rights, licenses, consents, legal documentation, and authority for the content you post, create, sell, and/or display through our Service and you have the rights to grant those rights to others. You are agreeing that such content does not contain any material subject to copyright, trademark, intellectual property or other such infringements. Reference to any products, services, processes or other information by name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by Bazaa Marketplace.
        </p>
        <p>
          We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes another's copyright or other intellectual property infringement.
        </p>
        <p>
          If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at{' '}
          <a href="mailto:support@bazaa.io" class="highlight">
            support@bazaa.io
          </a>{' '}
          and include in Your notice a detailed description of the alleged infringement.
        </p>
        <p>
          You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.
        </p>
        <p>DMCA Notice and DMCA Procedure for Copyright Infringement Claims.</p>
        <p>
          We will respond to and remove works as requested by any Digital Millennium Copyright Act (“DMCA”) takedown notices. If you believe that there is content on our Service that infringes on any copyright, trademark or intellectual property rights of any kind, please submit written notice that includes the following:
        </p>
        <p>
          An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.
        </p>
        <p>
          A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
        </p>
        <p>
          Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.
        </p>
        <p>Your address, telephone number, and email address.</p>
        <p>
          A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
        </p>
        <p>
          A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf. You can contact our copyright agent via email at{' '}
          <a href="mailto:support@bazaa.io" class="highlight">
            support@bazaa.io
          </a>{' '}
          Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.
        </p>

        <h5>Intellectual Property</h5>
        <p>
          The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.
        </p>
        <p>
          The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
        </p>
        <p>
          Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company
        </p>

        <h5>Your Feedback to Us</h5>
        <p>
          You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.
        </p>

        <h5>Links to Other Websites</h5>
        <p>
          Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
        </p>
        <p>
          The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
        </p>
        <p>
          We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
        </p>

        <h5>Termination</h5>
        <p>
          We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
        </p>
        <p>
          Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.
        </p>

        <h5>Limitation of Liability</h5>
        <p>
          To the fullest extent permitted by law, you agree that in no event will Bazaa Marketplace or its service suppliers be liable to you or any third party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from these terms or the service, products or third-party sites and products, or for any damages related to loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, or loss of data, breach of contract, or otherwise, even if foreseeable and even if Bazaa Marketplace or its service providers have been advised of the possibility of such damages. Further, for any other claim, demand, or damages whatsoever resulting from or arising out of or in connection with these terms of the delivery, use, or performance of the service. Access to, and use of, the service, products or third-party sites, and products are at your own discretion and risk, and you will be solely responsible for any damage to your computer system or mobile device or loss of data resulting therefrom.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.
        </p>

        <h5>"AS IS" and "AS AVAILABLE" Disclaimer</h5>
        <p>
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
        </p>

        <h5>Governing Law</h5>
        <p>
          The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
        </p>

        <h5>Disputes Resolution</h5>
        <p>
          If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
        </p>

        <h5>Assumption of Risk</h5>
        <p>You accept and acknowledge:</p>
        <p>
          The prices and liquidity of cryptocurrency assets (including any NFTs) are extremely volatile. You acknowledge that you fully understand this subjectivity and volatility and that you may lose money.
        </p>
        <p>
          A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact the development of those ecosystems and related applications, and could therefore also negatively impact the potential utility of NFTs.
        </p>
        <p>
          You are solely responsible for determining what, if any, Taxes apply to your transactions through the Services.
        </p>
        <p>
          The regulatory regime governing blockchain technologies, non-fungible tokens, cryptocurrency, and other crypto-based items is uncertain, and new regulations or policies may materially adversely affect the development of the Service and the utility of NFTs.
        </p>
        <p>
          We do not control the public blockchains that you are interacting with and we do not control any smart contracts that may be integral to your ability to complete transactions on these public blockchains. Transactions in NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable
          There are risks associated with using an Internet based currency, including but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that Bazaa Marketplace will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Service or any Blockchain network, however caused.
        </p>
        <p>
          The Service may rely on third-party platforms and/or vendors. If we are unable to maintain a good relationship with such platform providers; if the terms and conditions or pricing of such platform providers change; if we violate or cannot comply with the terms and conditions of such platforms; or if any of such platforms loses market share or falls out of favor or is unavailable for a prolonged period of time, access to and use of the Service may suffer.
        </p>
        <p>
          Bazaa Marketplace reserves the right to hide collections, contracts, and items affected by any of the issues mentioned in these terms. This may result in items you purchase becoming inaccessible on Bazaa Marketplace. The inability to view items on Bazaa Marketplace or an inability to use the Service in conjunction with the purchase, sale, or transfer of items available on any blockchains do not serve as grounds for a claim against Bazaa Marketplace.
          Any disputes with one or more users, release us from demands, claims or damages of every kind, known and unknown, arising out of any such disputes.
        </p>

        <h5>Indemnification</h5>
        <p>
          By agreeing to these Terms, you agree, to the full extent permitted by applicable law, to indemnify, defend, and hold harmless Bazaa Marketplace., and our respective past, present, and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the “Bazaa Marketplace”), from and against all actual or alleged claims, damages, awards, judgments, losses, liabilities, obligations, taxes, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses), and costs (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to:
        </p>
        <p>
          Your use or misuse of the Service, content, NFTs, or content linked to or associated with any NFTs.
        </p>
        <p>Any Feedback you provide.</p>
        <p>Your violation or breach of any term of these Terms or applicable law.</p>
        <p>
          Your violation of the rights of or obligations to a third party, including another user or third-party
          Your negligence or wilful misconduct.
        </p>
        <p>
          You further agree that the Bazaa Marketplace shall have control of the defense or settlement of any Claims. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written agreement between you and Bazaa Marketplace.
        </p>

        <h5>For European Union (EU) Users</h5>
        <p>
          If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.
        </p>

        <h5>United States Legal Compliance</h5>
        <p>
          You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
        </p>

        <h5>Severability and Waiver</h5>
        <h5>Severability</h5>
        <p>
          If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
        </p>

        <h5>Waiver</h5>
        <p>
          Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
        </p>

        <h5>Translation Interpretation</h5>
        <p>
          These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
        </p>
        <h5>Changes to These Terms and Conditions</h5>
        <p>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. You will be notified about the changes made within these Terms and Conditions.
        </p>
        <p>
          By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
        </p>

        <h5>Contact Us</h5>
        <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
        <p>
          By email:{' '}
          <a href="mailto:support@bazaa.io" class="highlight">
            support@bazaa.io
          </a>
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;
