import React, { useContext, useEffect, useState } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
import {
  addCollectionNftFilters,
  fetchCollection,
  fetchNftListingFilters,
} from '../actions/marketplace_action';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import useCollectionAnalyticsData from '../layouts/useCollectionAnalyticsData';
import LoaderComponent from './customs/loader/LoaderComponent';
import {canSaveClick, saveCollectionClick} from "../common/collectDetailUtils";

const CollectionDetail = (props) => {
  const { store } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const nftQuery = useSelector((state) => state.nfts_listing_query);
  const CollectionDetailComponent = useDynamicComponent('CollectionDetail', store);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const contractAddress = paths[paths?.length - 1].toLowerCase();
  const ownerExists = nftQuery.ownerId;
  const contractExists = nftQuery.collection_id;
  useCollectionAnalyticsData({ contractAddress });
  const { code } = useParams();

  useEffect(() => {
    if (canSaveClick()) {
      saveCollectionClick(code);
    }
  }, [code]);

  if (!contractExists) {
    if (ownerExists) {
       nftQuery.ownerId = '';
    }
    nftQuery.contractAddr= contractAddress;
    // dispatch(addCollectionNftFilters(nftQuery));
  }

  useEffect(() => {
    dispatch(fetchCollection(contractAddress)).then((collection) => {
      setSelectedCollection(collection);
      setLoading(false);
    });
  }, [contractAddress, dispatch]);

  useEffect(() => {
    dispatch(fetchNftListingFilters(contractAddress));
  }, [contractAddress, dispatch]);

  if (!CollectionDetailComponent || loading) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <CollectionDetailComponent selectedCollection={selectedCollection} />
    </>
  );
};

export default CollectionDetail;
