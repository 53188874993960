import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
//
import LoaderComponent from './customs/loader/LoaderComponent';

const CollectionListing = (props) => {
  const { store } = useContext(StoreContext);
  const MarketplaceComponent = useDynamicComponent('CollectionListing', store);

  if (!MarketplaceComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <MarketplaceComponent />
    </>
  );
};

export default CollectionListing;
