import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
import { useSelector } from 'react-redux';
import { selectProfile } from '../selectors/profile_selector';

import LoaderComponent from './customs/loader/LoaderComponent';
const MyProfile = (props) => {
  const { store } = useContext(StoreContext);
  const MyProfileComponent = useDynamicComponent('MyProfile', store);
  const profile = useSelector(selectProfile);

  if (!MyProfileComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <MyProfileComponent profile={profile} />
    </>
  );
};

export default MyProfile;
