const initialState = {
    limit: 24,
    filter: 'monthly',
    page: 1
  };
  
  const PopularListingQueryReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_POPULAR_LISTING_QUERY':
        return action.filters;
      case 'RESET_POPULAR_LISTING_QUERY':
        return initialState;
      default:
        return state;
    }
  };
  
  export default PopularListingQueryReducer;
  