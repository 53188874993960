import Web3 from 'web3';
import { parseEther } from 'ethers';
import { toast } from 'react-toastify';
const CryptoJS = require("crypto-js");
export const parseBlockChainError = (error) => {
  if (error?.shortMessage) {
    error = error?.shortMessage;
  }
  if (error?.message !== null && error?.message !== undefined) {
    error = error?.message;
  }
  try {
    error = error.toString();
    const open = error.indexOf('{');
    const close = Number(error.lastIndexOf('}')) + 1;
    const replacedString = error.substring(open, close);
    const parsedData = JSON.parse(replacedString);
    const spltedStrings = parsedData?.message?.split(':');
    if (spltedStrings?.length > 1) {
      parsedData.message = spltedStrings.slice(1).join(', ');
    }
    if (parsedData?.message?.includes('Wallet cap limit reached')) {
      parsedData.message = `${parsedData.message}\n ${'Please switch your wallet.'}`;
    }

    return parsedData;
  } catch (exc) {}
  if (error?.includes('does not match the target chain')) {
    return 'Please switch to Vanar Chain.';
  }
  return error;
};

export function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export function getFileExtension(fileName) {
  return fileName?.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
}
// export function toEther(value) {

//   const amount = Web3.utils.toHex(Web3.utils.toWei(String(value), 'ether'))
//   return Web3.utils.toWei(String(1), 'ether')
//   // return parseEther(value.toString());
// }
export function toEther(value) {
  try {
    return parseEther(value.toString());
  } catch (error) {
    return parseEther(Number(value).toFixed(5).toString());
  }
}
export const toChecksumAddress = (address) => {
  return Web3.utils.toChecksumAddress(address);
};
export const getParsedMessage = (error) => {
  if (error?.name !== null && error?.name !== undefined) {
    if (error?.name === 'ResourceUnavailableRpcError') {
      return 'Connect request is already pending. Please cancel pending request and try again.';
    }
    if (error?.name === 'TransactionExecutionError' && !error?.details?.includes('JSON-RPC')) {
      return 'Transaction Rejected.';
    }
    if (error?.name === 'ConnectorNotFoundError') {
      return 'The required wallet was not found.';
    }
    if (error?.details?.includes('JSON-RPC')) {
      return 'Insufficient balance: You do not possess the required amount of Vanry for this transaction.';
    }
    if (error?.message?.includes('User rejected the request')) {
      return 'Transaction Rejected.';
    }
    if (
      error?.details?.includes('insufficient funds') ||
      error?.details?.includes('insufficient balance')
    ) {
      return 'The total cost for this transaction (including additional costs) exceeds your available balance.';
    }

    if (
      error?.shortMessage?.includes('Invalid qunatity')
    ) {
      return 'These Items are no longer available. Try choosing something else.';
    }
    if (
      error?.details?.includes(
        'The contract function "isApprovedForAll" returned no data ("0x")',
      ) ||
      error?.shortMessage?.includes('The contract function "isApprovedForAll" returned no data ("0x")')
    ) {
      return 'The contract does not support approval for tokens transfer.';
    }
    if (error?.details?.includes('gas required exceeds allowance')) {
      return 'The total cost for this transaction (including additional costs) exceeds your available balance.';
    }
    if (error?.details?.includes('invalid document')) {
      return 'Invalid information provided!';
    }
  }

  return error?.shortMessage ?? error?.message ?? 'Something went wrong!';
};
/* global BigInt */
export const generateNumericSalt = () => {
  const maxValue = BigInt('2') ** BigInt('128') - BigInt('1');

  const randomBigInt = BigInt(Math.floor(Math.random() * Number(maxValue)));
  const str = BigInt(randomBigInt + '' + Date.now());
  return str;
};
/* global BigInt */
export const convertSaltToBigInt = (salt) => {
  const str = BigInt(salt);
  return str;
};
const readAsText = (fileUploaded) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Set up an event listener for when the file reading is complete
    reader.onload = () => {
      resolve(reader.result); // Use reader.result to get the content
    };

    reader.onerror = reject;

    // Make sure to pass the file to readAsText method
    reader.readAsText(fileUploaded);
  });
};

const isSVG = async (fileUploaded) => {
  try {
    const svgtxt = await readAsText(fileUploaded);

    // Parse the SVG text
    const validity = svgtxt.includes('<?xml') && svgtxt.includes('<svg');
    if (validity) {
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(svgtxt, 'text/xml');

      // Check for parsing errors
      let errors = xmlDoc.getElementsByTagName('parsererror');

      if (errors.length > 0) {
        // There are parsing errors
        throw new Error();
      } else {
        // SVG parsed successfully
        return true;
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    return false;
  }
};

const check = (headers) => {
  return (
    buffers,
    options = {
      offset: 0,
    },
  ) => headers.every((header, index) => header === buffers[options.offset + index]);
};

const readBuffer = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(new Uint8Array(reader.result));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};
const isPNG = check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
const isJPEG = check([0xff, 0xd8, 0xff]);
const isGIF = check([0x47, 0x49, 0x46, 0x38]);
const isWebp = check([0x52, 0x49, 0x46, 0x46]);
const isPngNotCorrupted = check([
  0x00, 0x00, 0x00, 0x00, 0x49, 0x45, 0x4e, 0x44, 0xae, 0x42, 0x60, 0x82,
]);
const isJpegNotCorrupted = check([0xff, 0xd9]);
export const isValidImageFile = async (fileUploaded) => {
  try {
    const buffers = await readBuffer(fileUploaded);
    const uint8Array = buffers;
    switch (fileUploaded.type) {
      case 'image/png':
        if (
          !isPNG(uint8Array) ||
          !isPngNotCorrupted(uint8Array, { offset: uint8Array.length - 12 })
        ) {
          toast('Invalid file type. Please select a PNG file.');
          return false;
        }
        // Further processing for PNG
        return true;

      case 'image/jpeg':
        if (
          !isJPEG(uint8Array) ||
          !isJpegNotCorrupted(uint8Array, { offset: uint8Array.length - 2 })
        ) {
          toast('Invalid file type. Please select a JPEG file.');
          return false;
        }
        // Further processing for JPEG
        return true;

      case 'image/gif':
        if (!isGIF(uint8Array)) {
          toast('Invalid file type. Please select a GIF file.');
          return false;
        }
        // Further processing for GIF
        return true;

      case 'image/svg+xml':
        const isValidSVG = await isSVG(fileUploaded);
        if (!isValidSVG) {
          toast('Invalid file type. Please select a SVG file.');
          return false;
        }
        //Futher processing svg file
        return true;
      case 'image/webp':
        if (!isWebp(uint8Array)) {
          toast('Invalid file type. Please select a Webp file.');
          return false;
        }
        // Further processing for Webp
        return true;
      default:
        throw new Error('Unsupported file type. Please select a PNG, JPEG, SVG or GIF file.');
    }
  } catch (error) {
    toast(error.message); // Log the error for debugging
    return false;
  }
};
export function aesEncrypt(secretKey, message) {
  let ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
  return ciphertext
}
export function aesDecrypt(secretKey, message) {
  let bytes  = CryptoJS.AES.decrypt(message, secretKey);
  let originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText
}