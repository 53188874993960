import React, { useContext } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
//
import LoaderComponent from './customs/loader/LoaderComponent';

const OwnerCollections = (props) => {
  const { store } = useContext(StoreContext);
  const OwnerCollectionComponent = useDynamicComponent('OwnerCollections', store);

  if (!OwnerCollectionComponent) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <OwnerCollectionComponent />
    </>
  );
};

export default OwnerCollections;
