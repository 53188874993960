import React, { useContext, useEffect, useState } from 'react';
import StoreContext from '../context/StoreContext';
import useDynamicComponent from './useDynamicComponent';
import LoaderComponent from './customs/loader/LoaderComponent';


const EachNftListing = (props) => {
  const { store } = useContext(StoreContext);
  const EachNftListingComponent = useDynamicComponent('EachNftListing', store);
 
  if (!EachNftListingComponent ) {
    return (
      <div id="wrapper">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <EachNftListingComponent  />
    </>
  );
};

export default EachNftListing;
