let initialState = {
    limit:24,
    page:1,
    sortAt:"created_at",
    sortTerm:"desc",
    filter:[],
    contractAddr:'',
    status:[],
    amount:[],
    type:'',
    creatorBy:'',
    ownerId:'',
    searchTerm:'',
    protocol: 'ERC-721'
   };
   
   const ProfileListingQueryReducer = (state = { ...initialState }, action) => {
     switch (action.type) {
       case 'SET_PROFILE_NFTS_LISTING_FILTERS':
         return {...state,...action.filters};
       case 'RESET_PROFILE_NFTS_LISTING_FILTERS': {
         return {
          limit:24,
          page:1,
          sortAt:"created_at",
          sortTerm:"desc",
          filter:[],
          contractAddr:'',
          status:[],
          amount:[],
          type:'',
          creatorId:'',
          ownerId:'',
          searchTerm:'',
          protocol: 'ERC-721'
         };
       }
       default:
         return state;
     }
   };
   
   export default ProfileListingQueryReducer;
   