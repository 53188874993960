let initialState = {};

const collection_analytics_reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COLLECTION_ANALYTICS':
      return action.analytics;
    case 'SET_COLLECTION_ANALYTICS_LOADING':
      return { ...state, analytics_loading: action.analytics_loading };
    default:
      return state;
  }
};

export default collection_analytics_reducer;
