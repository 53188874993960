import Common from "../helpers/common.helper";

const saveCollectionClick = async (collectionId) => {
    const createdAt = Date.now();
    const docData = {
        collection_id: collectionId,
        created_at: createdAt,
    };

    try {
        await Common.setDocument(
            '/stores/vanar/collection_click_counter',
            createdAt.toString(),
            docData,
        );
        localStorage.setItem('lastCollectionClick', createdAt.toString());
    } catch (error) {
        console.error('Error writing document: ', error);
    }
};

const canSaveClick = () => {
    const lastClickTime = localStorage.getItem('lastCollectionClick');
    if (lastClickTime) {
        const currentTime = Date.now();
        const oneMinute = 60000; // 1 minute in milliseconds
        return (currentTime - lastClickTime) > oneMinute;
    }
    return true;
};

export { saveCollectionClick, canSaveClick };
