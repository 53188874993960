import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { cross } from '../assets';
import { appLogoSmall } from '../../../assets/images';

const ModalErrorMsg = ({
  errorMessage,
  visibility,
  handleCloseModal,
  btnTitle,
  headerTitle,
  isErrorImage,
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="fw-520"
      contentClassName="border-box top d-flex align-items-center justify-content-center p-3"
      show={visibility}
      centered
    >
      {/* <Modal.Header className="border-0" closeButton>
        <Modal.Title>Alert!</Modal.Title>
      </Modal.Header> */}
      <button
        className="btn-close ml-auto"
        onClick={() => {
          handleCloseModal?.(false);
        }}
      >
      </button>
      <div className="d-flex align-items-center justify-content-center flex-column error_message_modal">
        <div className="modal-title text-center">
          <h3
            className={`title md text-capitalize ${
              isErrorImage === true || isErrorImage === undefined ? 'mb-2' : 'mb-12'
            }`}
          >
            {headerTitle !== null ? headerTitle : <>Alert!</>}
          </h3>
          {/* {(isErrorImage === true || isErrorImage === undefined) && (
            <img className="info-img" src={appLogoSmall} alt="danger" />
          )} */}
          <p className="mb-0">{errorMessage}</p>
        </div>

        <button
          style={{ width: 200 }}
          className={`btn-main mt-4 ${
            (isErrorImage === true || isErrorImage === undefined) && 'mt-0'
          }`}
          onClick={() => {
            handleCloseModal?.(true);
          }}
        >
          {btnTitle}
        </button>
      </div>
    </Modal>
  );
};

export default ModalErrorMsg;
