import React, { memo, useContext } from 'react';
import useDynamicComponent from '../useDynamicComponent';
import StoreContext from '../../context/StoreContext';
const MainHeader = () => {
  const Header = useDynamicComponent('headers/Header');


  if (!Header) {
    return <div></div>;
  }

  return (
    <>
      <Header />
    </>
  );
};


export default memo(MainHeader);
