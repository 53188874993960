// ListViewContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const ListViewContext = createContext();

// Create the provider component
export const ListViewProvider = ({ children }) => {
  const [isListViewClicked, setIsListViewClicked] = useState(false);

  // Define a function to update the state
  const updateListViewClicked = (value) => {
    setIsListViewClicked(value);
  };

  return (
    <ListViewContext.Provider value={{ isListViewClicked, updateListViewClicked }}>
      {children}
    </ListViewContext.Provider>
  );
};
